import React from 'react';
import {Dark, Light} from '../../../../Theme/Theme';
import moment from 'moment';
import {useSelector} from 'react-redux';

export default function OrderCard(props) {
  const item = props.item;
  const theme = useSelector(state => state.theme);
  return (
    <div
      style={{
        margin: 10,
        height: 70,
        backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        display:"flex"
      }}>
      <div
        style={{
          flex: 1,
          paddingLeft: 10,
          display:"flex",
          flexDirection:"column",
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}>
        <div
          style={{
            color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
            fontSize: 11,
          }}>
          {item.buy_sell == 0 ? 'BUY' : 'SELL'} : {item.qty} ₹ : {item.rate}
        </div>
        <div
          style={{
            color: theme == 0 ? Dark.text : Light.text,
            fontSize: 12,
            marginTop: 5,
            display:"flex",
          }}>
          {item.symbol}
          <div
            style={{
              fontSize: 8,
              color: theme == 0 ? Dark.text : Light.text,
              marginLeft:5,
              paddingTop:3
            }}>
            {moment(item.expiry_date).format('DD-MMM')}
          </div>
        </div>
        <div
          style={{
            color: theme == 0 ? Dark.text : Light.text,
            fontSize: 10,
            marginTop: 5,
          }}>
          {moment(item.date_created).format('DD-MM-YYYY hh:mm:ss')}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display:"flex",
          flexDirection:"column",
          paddingRight: 10,
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}>
        <div
          style={{
            color: Dark.sell,
            fontSize: 10,
          }}>
          {'BROKRAGE' + ':' + item.brokerage_client_amt} ({item.brokerage_client})
        </div>
        <div
          style={{
            color: item.profit_loss >= 0 ? Dark.buy : Dark.sell,
            fontSize: 14,
            marginTop: 5,
          }}>
          {parseFloat(item.profit_loss).toFixed(2)}
        </div>
        <div
          style={{
            color:
              item.type == 'NEW SAUDA'
                ? Dark.primary
                : item.type == 'SAUDA ADDED'
                ? Dark.buy
                : item.type == 'SAUDA REMOVE'
                ? Dark.sell
                : item.type == 'SAUDA SQ.Off'
                ? Dark.sell
                : Dark.sell,
            fontSize: 10,
            marginTop: 5,
          }}>
          {item.type}
        </div>
      </div>
    </div>
  );
}
