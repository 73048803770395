import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import Card from "./Card";
import { useSelector } from "react-redux";
const backend = new Backend();

export default function NiftyBankNifty() {
  const theme = useSelector((state) => state.theme);
  const [index, setIndex] = useState([]);

  useEffect(() => {
    LoadIndex();
  }, []);

  const LoadIndex = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_index(data).then((r) => {
      if (r.error == "False") {
        setIndex(r.index);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        padding: 5,
        backgroundColor: theme == 0 ? Dark.background : Light.background,
      }}
    >
      <div
        style={{
          color: theme == 0 ? Dark.text : Light.text,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {index.length > 0
          ? index.map((i) => {
              return <Card item={i} key={i.id} />;
            })
          : null}
      </div>
    </div>
  );
}
