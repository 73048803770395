import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { BiChevronDown } from "react-icons/bi";
import { AiFillCloseCircle, AiFillCloseSquare } from "react-icons/ai";
import { Dark, Light } from "../../../../../Theme/Theme";
import moment from "moment/moment";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import SymbolCard from "./SymbolCard";
import { useSelector } from "react-redux";

const backend = new Backend();

export default function AddSymbol(props) {
  const show = useSelector((state) => state.show);
  const [search_text, setsearch_text] = useState("");

  const [script, setScript] = useState([]);
  const close = () => {
    props.close();
  };

  const close_search = () => {
    setsearch_text("");
    find_script("");
  };

  const find_script = (a) => {
    let data = {
      name: a,
      server: localStorage.getItem("server"),
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      watchlist_id: props.select,
      name: a,
    };

    backend.find_symbol(data).then((r) => {
      if (r.error == "False") {
        setScript(r.symbol);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const delete_script = (a) => {
    let data = {
      server: localStorage.getItem("server"),
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      symbol_id: a,
      watchlist_id: props.select,
    };

    backend.delete_symbol(data).then((r) => {
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.loadWatchlist();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: show == 0 ? window.innerHeight - 145 : window.innerHeight - 395,
        width: "100%",
        backgroundColor: Dark.background,
      }}
    >
      <div
        style={{
          height: 50,
          padding: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Dark.secondary,
        }}
      >
        <div
          style={{
            height: 50,
            width: 55,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={close}
            style={{
              height: 45,
              width: 40,
              backgroundColor: Dark.background,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BiChevronDown size={35} />
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <InputText
            className={"searchInput"}
            value={search_text}
            onChange={(e) => {
              setsearch_text(e.target.value);
              find_script(e.target.value);
            }}
            style={{
              backgroundColor: Dark.background,
              color: Dark.text,
              height: 45,
              width: "100%",
              borderStyle: "solid",
              borderColor: Dark.background,
            }}
            autoComplete="new-password"
            placeholder="Search Symbol"
          />
        </div>

        <div
          style={{
            height: 50,
            width: 55,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={close_search}
            style={{
              height: 45,
              width: 40,
              backgroundColor: Dark.background,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AiFillCloseCircle size={30} color={Dark.sell} />
          </div>
        </div>
      </div>
      {search_text.length > 0 ? (
        <div
          style={{
            height: 290,
            width: 300,
            top: 120,
            right: 25,
            backgroundColor: Dark.secondary,
            position: "absolute",
            borderRadius: 10,
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            zIndex: 99,
          }}
        >
          {script.length > 0 ? (
            script.map((i2) => {
              return (
                <SymbolCard
                  item={i2}
                  select={props.select}
                  loadWatchlist={props.loadWatchlist}
                />
              );
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 16 }}>No Symbol Found</div>
            </div>
          )}
        </div>
      ) : null}

      <div
        style={{
          height:
            show == 0 ? window.innerHeight - 170 : window.innerHeight - 450,
          overflowY: "scroll",
        }}
      >
        {props.watchlist.length > 0 ? (
          props.watchlist.map((i1) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 60,
                  borderStyle: "solid",
                  borderWidth: 0,
                  borderBottomWidth: 1,
                  borderBottomColor: Dark.primary,
                  padding: 10,
                }}
              >
                <div
                  style={{
                    height: 45,
                    width: 55,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <HiOutlineDocumentReport size={30} color={Dark.active} />
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ display: "flex" }}>
                    {i1.symbol_display}{" "}
                    <div style={{ fontSize: 10, marginTop: 7, marginLeft: 5 }}>
                      {" "}
                      {moment(i1.expiry_date).format("DD-MMM")}
                    </div>{" "}
                  </div>
                </div>
                <div
                  onClick={() => {
                    delete_script(i1.id);
                  }}
                  style={{
                    height: 45,
                    width: 55,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AiFillCloseSquare size={30} color={Dark.sell} />
                </div>
              </div>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              height: window.innerHeight - 170,
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25 }}>Nothing here</div>
            <div>Use the search bar to add instruments.</div>
          </div>
        )}
      </div>
      
    </div>
  );
}
