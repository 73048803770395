import React from "react";
import { AiOutlineHolder } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Dark, Light } from "../../../../../Theme/Theme";

export default function Menu(props) {
  const theme = useSelector((state) => state.theme);
  return (
    <div
      style={{
        height: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme == 0 ? Dark.background : Light.background,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <a
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AiOutlineHolder
            style={{
              fontSize: 25,
              color: theme == 0 ? Dark.text : Light.text,
            }}
          />
        </div>
      </a>
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        <div>SYMBOL</div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        BID
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        ASK
      </div>
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        LTP
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        CHANGE
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        HIGH
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        LOW
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        OPEN
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        CLOSE
      </div>
    </div>
  );
}
