import React, { useState } from "react";
import { MdAddBox } from "react-icons/md";
import Backend from "../../../../../Backend/Backend";
import { Dark, Light } from "../../../../../Theme/Theme";
import { toast } from "react-toast";
import moment from "moment/moment";
import { AiFillCheckCircle } from "react-icons/ai";
const backend = new Backend();
export default function SymbolCard(props) {
  const item = props.item;

  const [show, setShow] = useState(false);

  const add_script = (a) => {
    let data = {
      server: localStorage.getItem("server"),
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      symbol: a,
      watchlist_id: props.select,
    };
    backend.add_symbol(data).then((r) => {
      if (r.error == "False") {
        props.loadWatchlist();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      onClick={() => {
        add_script(item);
        setShow(true);
      }}
      style={{
        height: 55,
        paddingLeft: 20,
        paddingRight: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Light.text,
        width: "100%",
      }}
    >
      <div style={{ flex: 1 }}>
        {item.symbol_display}
        <div style={{ fontSize: 10 }}>
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div>
        <div
          style={{
            height: 45,
            width: 55,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {show ? (
            <AiFillCheckCircle size={30} color={Dark.primary} />
          ) : (
            <MdAddBox size={30} color={Dark.buy} />
          )}
        </div>
      </div>
    </div>
  );
}
