import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
export default function Withdrowal(props) {
  const profile = props.profile
  const [amount, setAmount] = useState('');
  return (
    <div>
      <div
        style={{
          height: 280,
          backgroundColor: Dark.secondary,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: Dark.text,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Dark.primary,
            paddingLeft: 15,
            paddingRight: 15,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          CURRENT BALANCE - {profile.deposit_current}
        </div>
        <div
          style={{
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: 50, color: Dark.text }}>AMOUNT</div>
          <div>
            <InputText
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              type="number"
              placeholder="Enter Amount"
            />
          </div>
        </div>

        <div
          style={{
            height: 100,
            display: "flex",
          }}
        >
          <div
            style={{
              paddingRight: 100,
              color: Dark.text,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ borderRadius: 20 }}
              height={80}
              src={require("../../../../../Photos/Upi.png")}
            />
          </div>
          <div
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ borderRadius: 20 }}
              height={80}
              src={require("../../../../../Photos/Bank.jpg")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
