import React, { useContext, useEffect, useState } from "react";
import { AiOutlineHolder } from "react-icons/ai";
import { Dark, Light } from "../../../../../Theme/Theme";
import moment from "moment";
import SocketContext from "../../../../../SocketContext/SocketContext";
import { useSelector } from "react-redux";
import { RiDeleteBin5Line } from "react-icons/ri";
import Backend from "../../../../../Backend/Backend";
import "./WatclistCard.css";
import { toast } from "react-toast";
const backend = new Backend();

export default function WatchlistCard(props) {
  const item = props.item;
  const theme = useSelector((state) => state.theme);
  const socket = useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);

  const [bidcolor, setbidcolor] = useState("");
  const [askcolor, setaskcolor] = useState("");
  const [ltpcolor, setltpcolor] = useState("");

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);
    socket.on("trade" + item.symbol_id, trade);
    socket.on("bidask" + item.symbol_id, bidask);
    return () => {
      socket.off("trade" + item.symbol_id, trade);
      socket.off("bidask" + item.symbol_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (bid !== msg.Bid) {
        if (msg.Bid > 0) {
          if (msg.Bid > bid) {
            setbidcolor(Dark.buy);
          } else {
            setbidcolor(Dark.sell);
          }
          setbid(msg.Bid);
        }
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > 0) {
          if (msg.Ask > ask) {
            setaskcolor(Dark.buy);
          } else {
            setaskcolor(Dark.sell);
          }
          setask(msg.Ask);
        }
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        if (msg.LTP > 0) {
          if (msg.LTP > ltp) {
            setltpcolor(Dark.buy);
          } else {
            setltpcolor(Dark.sell);
          }
          setltp(msg.LTP);
        }
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
    }
  };
  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      if (msg.Bid > 0) {
        if (msg.Bid > bid) {
          setbidcolor(Dark.buy);
        } else {
          setbidcolor(Dark.sell);
        }
        setbid(msg.Bid);
      }
    }
    if (ask !== msg.Ask) {
      if (msg.Ask > 0) {
        if (msg.Ask > ask) {
          setaskcolor(Dark.buy);
        } else {
          setaskcolor(Dark.sell);
        }
        setask(msg.Ask);
      }
    }
  };

  const delete_symbol = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
      watchlist_id: props.selected,
      symbol_id: item.id,
    };

    backend.delete_symbol(data).then((r) => {
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      onClick={() => {
        props.update(props.index);
      }}
      key={item.id}
      style={{
        height: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 10,
        borderRadius: 10,
        backgroundColor: theme == 0 ? Dark.background : Light.background,
        position: "relative",
        borderStyle: "solid",
        borderWidth: 1,
        borderColor:
          props.count == props.index
            ? Dark.primary
            : theme == 0
            ? Dark.background
            : Light.background,
      }}
    >
      <a
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AiOutlineHolder style={{ fontSize: 25 }} />
        </div>
      </a>
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <div>{props.item.symbol_display}</div>
        <div
          style={{
            fontSize: 8,
            paddingTop: 5,
            color: theme == 0 ? Dark.sell : Light.sell,
          }}
        >
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 10,
          height: "80%",
          marginRight: 10,
        }}
      >
        <a
          style={{
            color:
              bidcolor == ""
                ? theme == 0
                  ? Dark.text
                  : Light.background
                : bidcolor,
          }}
        >
          {bid}
        </a>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
          borderRadius: 10,
          height: "80%",
        }}
      >
        <div
          style={{
            color:
              askcolor == ""
                ? theme == 0
                  ? Dark.text
                  : Light.background
                : askcolor,
          }}
        >
          {" "}
          {ask}
        </div>
      </div>
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color:
            ltpcolor == "" ? (theme == 0 ? Dark.text : Light.text) : ltpcolor,
        }}
      >
        {ltp}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: chng > 0 ? Dark.buy : Dark.sell,
          fontSize: 14,
        }}
      >
        {parseFloat(chng).toFixed(2)}{" "}
        {"(" + parseFloat(percent).toFixed(2) + "%)"}
      </div>

      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        {high2}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        {low2}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        {open}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: theme == 0 ? Dark.text : Light.text,
        }}
      >
        {close}
      </div>
      {props.count == props.index ? (
        <div
          style={{
            position: "absolute",
            right: 0,
            backgroundColor: Dark.primary,
            height: "100%",
            width: "300px",
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                props.open(0);
              }}
              style={{
                backgroundColor: Dark.buy,
                padding: 10,
                borderRadius: 10,
                width: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: Dark.text,
              }}
            >
              BUY
            </div>
          </div>
          <div
            style={{
              width: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              onClick={() => {
                props.open(1);
              }}
              style={{
                backgroundColor: Dark.sell,
                padding: 10,
                borderRadius: 10,
                width: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: Dark.text,
              }}
            >
              SELL
            </div>
          </div>
          <div
            onClick={delete_symbol}
            style={{
              width: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RiDeleteBin5Line size={30} color={Dark.sell} />
          </div>
        </div>
      ) : null}
    </div>
  );
}
