import React, { useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import SocketContext from "../../../../SocketContext/SocketContext";

let ratebid = 0;
let rateask = 0;

function WactlistCard(props) {
  const i = props.item;
  const socket = useContext(SocketContext);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);

  const [bidcolor, setbidcolor] = useState("");
  const [askcolor, setaskcolor] = useState("");

  useEffect(() => {
    socket.emit("giverate", i.symbol_id);
    socket.on("trade" + i.symbol_id, trade);
    socket.on("bidask" + i.symbol_id, bidask);
    return () => {
      socket.off("trade" + i.symbol_id, trade);
      socket.off("bidask" + i.symbol_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (bid !== msg.Bid) {
        if (msg.Bid > 0) {
          setbid(msg.Bid);
          if (ratebid > msg.Bid) {
            setbidcolor(Dark.buy);
          } else {
            setbidcolor(Dark.sell);
          }
          ratebid = msg.Bid;
        }
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > 0) {
          setask(msg.Ask);

          if (rateask > msg.Ask) {
            setaskcolor(Dark.buy);
          } else {
            setaskcolor(Dark.sell);
          }
          rateask = msg.Ask;
        }
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        if (msg.LTP > 0) {
          setltp(msg.LTP);
        }
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
    }
  };
  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      if (msg.Bid > 0) {
        setbid(msg.Bid);

        if (ratebid > msg.Bid) {
          setbidcolor(Dark.buy);
        } else {
          setbidcolor(Dark.sell);
        }
        ratebid = msg.Bid;
      }
    }
    if (ask !== msg.Ask) {
      if (msg.Ask > 0) {
        setask(msg.Ask);

        if (rateask > msg.Ask) {
          setaskcolor(Dark.buy);
        } else {
          setaskcolor(Dark.sell);
        }
        rateask = msg.Ask;
      }
    }
  };

  return (
    <div
      key={i.id}
      onClick={() => {
        props.openModal(i);
      }}
      style={{
        display: "flex",
        height: 70,
        backgroundColor: Dark.background,
        width: "95%",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.secondary,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          marginLeft: 10,
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", fontSize: 14, fontWeight: "bold" }}>
          {i.symbol_display}{" "}
          <div style={{ fontSize: 10, marginTop: 6, marginLeft: 5 }}>
            {" "}
            {moment(i.expiry_date).format("DD-MMM")}
          </div>{" "}
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 7,
            color: chng > 0 ? Dark.buy : Dark.sell,
          }}
        >
          {" "}
          {parseFloat(chng).toFixed(2)}{" "}
          {"(" + parseFloat(percent).toFixed(2) + "%)"}
        </div>
      </div>
      <div
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 45,
            width: 85,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: Dark.secondary,
            padding: 10,
            borderRadius: 7,
            marginRight: 10,
          }}
        >
          <div
            style={{
              fontSize: 18,
              color: bidcolor == "" ? Dark.text : bidcolor,
            }}
          >
            {bid}
          </div>
          <div style={{ fontSize: 10 }}>L : {low2}</div>
        </div>
        <div
          style={{
            height: 45,
            width: 85,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: Dark.secondary,
            padding: 10,
            borderRadius: 7,
          }}
        >
          <div
            style={{
              fontSize: 18,
              color: askcolor == "" ? Dark.text : askcolor,
            }}
          >
            {ask}
          </div>
          <div style={{ fontSize: 10 }}>H : {high2}</div>
        </div>
      </div>
    </div>
  );
}

export default WactlistCard;
