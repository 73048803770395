import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import ArrowTrad from "../../../../Photos/ArrowTrad.png";
import LogoSub from "../../../../Photos/SubArrowTrad.png";
import { BiLogOut, BiUserCircle, BiSupport } from "react-icons/bi";
import { Dialog } from "primereact/dialog";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Wallet from "./Wallet/Wallet";
import Profile from "./Profile/Profile";
import Ledger from "./Ledger/Ledger";
import Logs from "./Logs/Logs";
import QtySetting from "./QtySetting/QtySetting";
import History from "./History/History";
import MarketTime from "./MarketTime/MarketTime";
import Setting from "./Setting/Setting";
import { confirmAlert } from "react-confirm-alert";
import { IoWallet } from "react-icons/io5";
import Backend from "../../../../Backend/Backend";

import hotkeys from "hotkeys-js";

import "./Topview.css";
import { toast } from "react-toast";

const backend = new Backend();

export default function TopView() {
  const path = window.location.pathname;
  const navigation = useNavigate();

  const theme = useSelector((state) => state.theme);
  const [select, setSelect] = useState(false);
  const [checked, setChecked] = useState(1);

  const [profile, setProfile] = useState(false);
  const [ledger, setLedger] = useState(false);
  const [log, setLog] = useState(false);
  const [qtysetting, setQtySetting] = useState(false);
  const [history, setHistory] = useState(false);
  const [time, setTime] = useState(false);
  const [setting, setSetting] = useState(false);
  const [wallet, setWallet] = useState(false);

  const [profiledata, setProfileData] = useState("");

  const [masterintraday, setMasterIntraday] = useState(0);

  useEffect(() => {
    setChecked(theme == 0 ? false : true);
    loadProfile();
    loadHidden();
  }, []);

  const Logout = () => {
    confirmAlert({
      title: "Confirm to Logout",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("id");
            localStorage.removeItem("username");
            localStorage.removeItem("server");
            localStorage.removeItem("rules");
            localStorage.removeItem("AuthState");
            window.location = "/";
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  const loadProfile = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.profile(data).then((r) => {
      if (r.error == "False") {
        setProfileData(r.profile);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadHidden = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };
    backend.hidden(data).then((r) => {
      if (r.error == "False") {
        setMasterIntraday(r.masterintraday);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  hotkeys("ctrl+w,ctrl+o,ctrl+t,ctrl+p", function (event, handler) {
    // Prevent the default refresh event under WINDOWS system
    event.preventDefault();

    if (event.ctrlKey == true) {
      if (event.key == "w" || event.key == "W") {
        navigation("/Watchlist");
      }

      if (event.key == "o" || event.key == "O") {
        navigation("/Standing");
      }

      if (event.key == "t" || event.key == "T") {
        navigation("/Trade");
      }

      if (event.key == "p" || event.key == "P") {
        navigation("/Pending");
      }
    }
  });

  let balance =
    profiledata == undefined
      ? 0
      : profiledata.deposit_current == null
      ? 0
      : profiledata.deposit_current;

  return (
    <div
      style={{
        height: 80,
        backgroundColor: theme == 0 ? Dark.background : Light.background,
        display: "flex",
      }}
    >
      <Link
        to={"/"}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img src={ArrowTrad} style={{ height: "70px" }} />
        <img src={LogoSub} style={{ height: "20px" }} />
      </Link>
      <div
        style={{
          flex: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={theme == 0 ? "linkdark" : "linklight"}
          onClick={() => {
            setSelect(!select);
            navigation("/Watchlist");
          }}
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: theme == 0 ? Dark.text : Light.text,
            backgroundColor:
              path == "/" || path == "/Watchlist"
                ? theme == 0
                  ? Dark.primary
                  : Light.primary
                : theme == 0
                ? Dark.background
                : Light.background,
            padding: 10,
            borderRadius: 7,
            marginLeft: 20,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            W
          </div>
          <div>ATCHLIST</div>
        </div>

        <div
          className={theme == 0 ? "linkdark" : "linklight"}
          onClick={() => {
            setSelect(!select);
            navigation("/Standing");
          }}
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: theme == 0 ? Dark.text : Light.text,
            backgroundColor:
              path == "/Standing"
                ? theme == 0
                  ? Dark.primary
                  : Light.primary
                : theme == 0
                ? Dark.background
                : Light.background,
            padding: 10,
            borderRadius: 7,
            marginLeft: 20,
          }}
        >
          <div>P</div>
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            0
          </div>
          <div>RTFOLIO</div>
        </div>

        <div
          className={theme == 0 ? "linkdark" : "linklight"}
          onClick={() => {
            setSelect(!select);
            navigation("/Pending");
          }}
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: theme == 0 ? Dark.text : Light.text,
            backgroundColor:
              path == "/Pending"
                ? theme == 0
                  ? Dark.primary
                  : Light.primary
                : theme == 0
                ? Dark.background
                : Light.background,
            padding: 10,
            borderRadius: 7,
            marginLeft: 20,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            P
          </div>
          <div>ENDING</div>
        </div>

        <div
          className={theme == 0 ? "linkdark" : "linklight"}
          onClick={() => {
            setSelect(!select);
            navigation("/Trade");
          }}
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 16,
            color: theme == 0 ? Dark.text : Light.text,
            backgroundColor:
              path == "/Trade"
                ? theme == 0
                  ? Dark.primary
                  : Light.primary
                : theme == 0
                ? Dark.background
                : Light.background,
            padding: 10,
            borderRadius: 7,
            marginLeft: 20,
          }}
        >
          <div
            style={{
              borderStyle: "solid",
              borderWidth: 0,
              borderBottomWidth: 2,
              borderBottomColor: Dark.sell,
            }}
          >
            T
          </div>
          <div>RADE</div>
        </div>
      </div>

      {masterintraday == 0 ? null : (
        <div
          style={{
            background: Dark.background,
            width: "180px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => {
              setSelect(!select);
              setWallet(true);
            }}
            style={{
              color: Dark.text,
              backgroundColor: Dark.primary,
              width: 170,
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
            }}
          >
            <div
              style={{
                paddingRight: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IoWallet
                style={{ color: Dark.text, fontSize: 22, marginRight: 10 }}
              />{" "}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div>BALANCE</div>
              <div style={{ paddingTop: 5 }}>
                {parseFloat(balance).toFixed(0)}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          flex: 1.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="dropdownprofile">
              <button class="dropbtnprofile">
                {" "}
                <BiUserCircle
                  color={theme == 0 ? Dark.text : Light.text}
                  fontSize={30}
                />{" "}
              </button>
              <div class="dropdown-contentprofile">
                <div
                  onClick={() => {
                    setSelect(!select);
                    setProfile(true);
                  }}
                  className="link"
                >
                  PROFILE
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setLedger(true);
                  }}
                  className="link"
                >
                  LADGER
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setLog(true);
                  }}
                  className="link"
                >
                  REJECATION LOGS
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setQtySetting(true);
                  }}
                  className="link"
                >
                  QTY SETTING
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setHistory(true);
                  }}
                  className="link"
                >
                  HISTORY
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setTime(true);
                  }}
                  className="link"
                >
                  MARKET TIME
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                    setSetting(true);
                  }}
                  className="link"
                >
                  SETTING
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div class="dropdownprofile">
              <button class="dropbtnprofile">
                {" "}
                <BiSupport
                  color={theme == 0 ? Dark.text : Light.text}
                  fontSize={30}
                />{" "}
              </button>
              <div class="dropdown-contentprofile">
                <div
                  onClick={() => {
                    setSelect(!select);
                  }}
                  className="link"
                >
                  Call/Phone - {profiledata.mobile}
                </div>
                <div
                  onClick={() => {
                    setSelect(!select);
                  }}
                  className="link"
                >
                  What's App - {profiledata.mobile}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              width: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={Logout}
          >
            <BiLogOut
              color={theme == 0 ? Dark.text : Light.text}
              fontSize={30}
            />
          </div>
        </div>
      </div>

      <Dialog
        showHeader={false}
        visible={wallet}
        style={{ width: "40vw", height: "55vh" }}
        onHide={() => setWallet(false)}
      >
        <Wallet />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={profile}
        style={{ width: "40vw", height: "65vh" }}
        onHide={() => setProfile(false)}
      >
        <Profile />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={ledger}
        style={{ width: "50vw", height: "65vh" }}
        onHide={() => setLedger(false)}
      >
        <Ledger />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={log}
        style={{ width: "50vw", height: "65vh" }}
        onHide={() => setLog(false)}
      >
        <Logs />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={qtysetting}
        style={{ width: "50vw", height: "65vh" }}
        onHide={() => setQtySetting(false)}
      >
        <QtySetting />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={history}
        style={{ width: "50vw", height: "65vh" }}
        onHide={() => setHistory(false)}
      >
        <History />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={time}
        style={{ width: "50vw", height: "65vh" }}
        onHide={() => setTime(false)}
      >
        <MarketTime />
      </Dialog>

      <Dialog
        showHeader={false}
        visible={setting}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setSetting(false)}
      >
        <Setting />
      </Dialog>
    </div>
  );
}
