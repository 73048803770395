import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { confirmAlert } from "react-confirm-alert";
import { AiOutlineUser, AiOutlineSetting } from "react-icons/ai";
import { GoBlocked, GoNumber } from "react-icons/go";
import { HiOutlineDocumentReport, HiOutlineKey } from "react-icons/hi";
import {
  MdOutlineAttachMoney,
  MdOutlineBlock,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { TbLogout } from "react-icons/tb";
import { BiTime } from "react-icons/bi";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const backend = new Backend();
export default function Profile() {
  const show = useSelector(state => state.show )
  const height = show == 0 ? window.innerHeight - 145 : window.innerHeight - 295 ;
  const [profile, setProfile] = useState("");
  useEffect(() => {
    LoadProfile();
  }, []);

  const LoadProfile = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.profile(data).then((r) => {
      if (r.error == "False") {
        setProfile(r.profile);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  useEffect(() => {
    LoadProfile();
  }, []);

  const Logout = () => {
    confirmAlert({
      title: "Confirm to Logout",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            localStorage.removeItem("token");
            localStorage.removeItem("id");
            localStorage.removeItem("username");
            localStorage.removeItem("server");
            localStorage.removeItem("rules");
            localStorage.removeItem("AuthState");
            window.location = "/";
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  };

  return (
    <div
      style={{
        height: height,
        backgroundColor: Dark.background,
        color: Dark.text,
        overflowY: "scroll",
      }}
    >
      <div style={{ padding: 10 }}>
        <div
          style={{
            height: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img height={100} src={require("../../../../Photos/ArrowTrad.png")} />
          <img height={35} src={require("../../../../Photos/SubArrowTrad.png")} />

          <div style={{ padding: 10, textAlign: "center" }}>
            <div> {profile == "" ? "Sauda" : profile.name}</div>
            <div style={{ paddingTop: 7, fontSize: 22 }}>
              {profile == "" ? "Sauda" : profile.username}
            </div>
          </div>
        </div>

        <Link
          to={"/"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <MdOutlineAttachMoney size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>BILL</div>
        </Link>

        <Link
          to={"/"}
          // to={"/Account"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <AiOutlineUser size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>PROFILE</div>
        </Link>

        <Link
          to={"/"}
          // to={"/Ledger"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <HiOutlineDocumentReport size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>LEDGER</div>
        </Link>

        <Link
          to={"/"}
          // to={"/Logs"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <GoBlocked size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>REJECTION LOGS</div>
        </Link>

        <Link
          to={"/"}
          // to={"/BlockSymbol"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <MdOutlineBlock size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>BLOCK SYMBOL</div>
        </Link>

        <Link
          to={"/"}
          // to={"/QtySetting"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <GoNumber size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>QTY SETTING</div>
        </Link>

        <Link
          to={"/"}
          // to={"/ChangePassword"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <HiOutlineKey size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>CHNAGE PASSWORD</div>
        </Link>

        <Link
          to={"/"}
          // to={"/MarketTime"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <BiTime size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>MARKET TIME</div>
        </Link>

        <Link
          to={"/"}
          // to={"/Setting"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <AiOutlineSetting size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>SETTING</div>
        </Link>

        <Link
          to={"/"}
          // to={"/Support"}
          className="linkdark"
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <MdOutlineSupportAgent size={25} color={Dark.text} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15 }}>CUSTOMER SUPPORT</div>
        </Link>

        <div
          onClick={Logout}
          style={{
            padding: 10,
            backgroundColor: Dark.secondary,
            marginBottom: 15,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div style={{ paddingLeft: 10 }}>
            <TbLogout size={25} color={Dark.sell} />
          </div>
          <div style={{ flex: 1, paddingLeft: 15, color: Dark.sell }}>
            LOGOUT
          </div>
        </div>
      </div>
    </div>
  );
}
