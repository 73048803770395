import React, { useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import ReactLoading from "react-loading";
import { toast } from "react-toast";
import Backend from "../../../../Backend/Backend";
import { useSelector } from "react-redux";
import "./Password.css";
const backend = new Backend();
export default function Password() {
  const height = window.innerHeight;
  const theme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);

  const [oldpassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [cpassword, setCPassword] = useState("");

  const [input1, setInput1] = useState(false);
  const [input2, setInput2] = useState(false);
  const [input3, setInput3] = useState(false);

  const onSubmit = () => {
    if (oldpassword == "") {
      toast.error("Invalid Server", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (newpassword == "") {
      toast.error("Invalid Username", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (cpassword != newpassword) {
      toast.error("Password are not same !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);

      let data = {
        server: localStorage.getItem("server"),
        id: localStorage.getItem("id"),
        token: localStorage.getItem("token"),
        oldpassword: oldpassword,
        cpassword: cpassword,
      };

      backend.cpassword(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          localStorage.removeItem("server");
          window.location = "/";
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  return (
    <div
      style={{
        height: height,
        backgroundColor: theme == 0 ? Dark.background : Light.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className={theme == 0 ? "loginviewdark" : "loginviewlight"}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              paddingBottom: 20,
            }}
          >
            <div style={{ color: Dark.primary, fontSize: 22 }}>
              CHANGE PASSWORD
            </div>
            <div style={{ color: Dark.text, fontSize: 12, paddingTop: 5 }}>
              ONE TIME ONLY
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
              <InputText
                value={oldpassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                style={{
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: input1 ? Dark.primary : Dark.background,
                }}
                onFocus={() => setInput1(true)}
                onBlur={() => setInput1(false)}
                placeholder="Enter Old Password"
                autocomplete="new-password"
              />
            </div>

            <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
              <InputText
                value={cpassword}
                onChange={(e) => {
                  setCPassword(e.target.value);
                }}
                style={{
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: input2 ? Dark.primary : Dark.background,
                }}
                onFocus={() => setInput2(true)}
                onBlur={() => setInput2(false)}
                placeholder="Enter New Password"
                autocomplete="new-password"
              />
            </div>
            <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
              <InputText
                value={newpassword}
                autoComplete="off"
                type="password"
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                style={{
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: input3 ? Dark.primary : Dark.background,
                }}
                onFocus={() => setInput3(true)}
                onBlur={() => setInput3(false)}
                placeholder="Confirm Password"
                autocomplete="new-password"
              />
            </div>

            <div
              onClick={loading ? null : onSubmit}
              className={
                theme == 0
                  ? "loginbuttondarkpassword"
                  : "loginbuttonlightpassword"
              }
            >
              {loading ? (
                <ReactLoading
                  type={"spin"}
                  color={Dark.text}
                  height={18}
                  width={18}
                />
              ) : (
                "SUBMIT"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
