import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import ArrowTrad from "../../../../Photos/ArrowTrad.png";
import { InputText } from "primereact/inputtext";
import ReactLoading from "react-loading";
import { toast } from "react-toast";
import { Checkbox } from "primereact/checkbox";
import Backend from "../../../../Backend/Backend";
import { useSelector } from "react-redux";
import "./Login.css";
import { useNavigate } from "react-router-dom";
const backend = new Backend();
export default function Login() {
  const height = window.innerHeight;
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);
  const [server, setServer] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);

  const [input1, setInput1] = useState(false);
  const [input2, setInput2] = useState(false);
  const [input3, setInput3] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("remember") == "1") {
      setServer(localStorage.getItem("server_rem"));
      setUsername(localStorage.getItem("username_rem"));
      setPassword(localStorage.getItem("password_rem"));
      setRemember(true);
    }
  }, []);

  const onSubmit = () => {
    if (server == "") {
      toast.error("Invalid Server", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (username == "") {
      toast.error("Invalid Username", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (password == "") {
      toast.error("Invalid Password", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);

      let data = {
        server: server,
        username: username,
        password: password,
      };

      backend.login(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          if (r.password == 0) {
            localStorage.setItem("token", r.token);
            localStorage.setItem("id", r.id);
            localStorage.setItem("server", r.server);
            navigate("/cpassword");
          } else {
            localStorage.setItem("token", r.token);
            localStorage.setItem("id", r.id);
            localStorage.setItem("username", r.username);
            localStorage.setItem("server", r.server);
            localStorage.setItem("AuthState", "1");

            let data_rem = {
              server: server,
              username: username,
              password: password,
            };
            if (remember) {
              localStorage.setItem("remember", "1");
              localStorage.setItem("server_rem", data_rem.server);
              localStorage.setItem("username_rem", data_rem.username);
              localStorage.setItem("password_rem", data_rem.password);
            } else {
              localStorage.setItem("remember", "0");
              localStorage.setItem("server_rem", data_rem.server);
              localStorage.setItem("username_rem", data_rem.username);
              localStorage.setItem("password_rem", data_rem.password);
            }
            toast.success(r.message, {
              backgroundColor: Dark.buy,
              color: Dark.text,
            });

            window.location = "/";
          }
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  const onSubmitDemo = (a, b, c) => {
    setLoading(true);

    let data = {
      server: a,
      username: b,
      password: c,
    };

    backend.login(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        localStorage.setItem("token", r.token);
        localStorage.setItem("id", r.id);
        localStorage.setItem("username", r.username);
        localStorage.setItem("server", r.server);
        localStorage.setItem("AuthState", "1");

        let data_rem = {
          server: server,
          username: username,
          password: password,
        };
        if (remember) {
          localStorage.setItem("remember", "1");
          localStorage.setItem("server_rem", data_rem.server);
          localStorage.setItem("username_rem", data_rem.username);
          localStorage.setItem("password_rem", data_rem.password);
        } else {
          localStorage.setItem("remember", "0");
          localStorage.setItem("server_rem", data_rem.server);
          localStorage.setItem("username_rem", data_rem.username);
          localStorage.setItem("password_rem", data_rem.password);
        }
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });

        window.location = "/";
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const demo = () => {
    backend.demo().then((r) => {
      setLoading(false);
      if (r.error == "False") {
        onSubmitDemo(r.server, r.username, r.password);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: height,

        backgroundColor: theme == 0 ? Dark.background : Light.background,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 15,
            position: "relative",
            top: 50,
            backgroundColor: Dark.background,
            borderRadius: 100,
            height: "120px",
            width: "120px",
          }}
        >
          <img src={ArrowTrad} style={{ height: "80px", width: "100px" }} />
        </div>
        <div className={theme == 0 ? "loginviewdark" : "loginviewlight"}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  marginBottom: 10,
                  fontSize: 18,
                  textAlign: "left",
                  paddingLeft: 5,
                  paddingTop: 50,
                }}
              >
                SERVER
              </div>
              <InputText
                value={server}
                onChange={(e) => {
                  setServer(e.target.value);
                }}
                style={{
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: input1 ? Dark.primary : Dark.background,
                }}
                onFocus={() => setInput1(true)}
                onBlur={() => setInput1(false)}
                placeholder="Enter Server"
                autocomplete="new-password"
              />
            </div>

            <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  marginBottom: 10,
                  fontSize: 18,
                  textAlign: "left",
                  paddingLeft: 5,
                }}
              >
                USERNAME
              </div>
              <InputText
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                style={{
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: input2 ? Dark.primary : Dark.background,
                }}
                onFocus={() => setInput2(true)}
                onBlur={() => setInput2(false)}
                placeholder="Enter Username"
                autocomplete="new-password"
              />
            </div>
            <div className={theme == 0 ? "loginInputDark" : "loginInputLight"}>
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  marginBottom: 10,
                  fontSize: 18,
                  textAlign: "left",
                  paddingLeft: 5,
                }}
              >
                PASSWORD
              </div>
              <InputText
                value={password}
                autoComplete="off"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                style={{
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: input3 ? Dark.primary : Dark.background,
                }}
                onFocus={() => setInput3(true)}
                onBlur={() => setInput3(false)}
                placeholder="Enter Password"
                autocomplete="new-password"
              />
            </div>
            <div
              style={{
                width: "93%",
              }}
            >
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  marginBottom: 10,
                  width: "100%",
                  fontSize: 18,
                  display: "flex",
                }}
              >
                <div style={{ paddingRight: 10 }}>
                  <Checkbox
                    className={theme == 0 ? "checkdark" : "checklight"}
                    checked={remember}
                    onChange={(e) => setRemember(e.checked)}
                  />
                </div>
                <div>Remember</div>
              </div>
            </div>

            <div
              onClick={loading ? null : onSubmit}
              className={theme == 0 ? "loginbuttondark" : "loginbuttonlight"}
            >
              {loading ? (
                <ReactLoading
                  type={"spin"}
                  color={Dark.text}
                  height={18}
                  width={18}
                />
              ) : (
                "SUBMIT"
              )}
            </div>
            <div
              onClick={loading ? null : demo}
              style={{
                backgroundColor: Dark.sell,
                fontSize: 12,
              }}
              className={theme == 0 ? "loginbuttondark" : "loginbuttonlight"}
            >
              {loading ? (
                <ReactLoading
                  type={"spin"}
                  color={Dark.text}
                  height={18}
                  width={18}
                />
              ) : (
                "LOGIN WITH DEMO ACOOUNT !"
              )}
            </div>

            <div
              style={{
                fontSize: 10,
                color: Dark.text,
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              THIS APPLICATION IS FOR TRAINING PURPOSE ONLY.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
