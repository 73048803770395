import React from "react";
import { Dark } from "../../../../Theme/Theme";
export default function TopCard(props) {
  let limit = props.limit == null ? 0 : props.limit;
  let total = props.total == null ? 0 : props.total;
  let sl = props.sl == null ? 0 : props.sl;
  return (
    <div
      style={{
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Dark.secondary,
        margin: 10,
        borderRadius: 10,
        marginTop: 0,
        textTransform:"uppercase"
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12, paddingBottom: 5, color: Dark.text }}>
          Limit
        </div>
        <div style={{ color: Dark.sell }}>{parseFloat(limit).toFixed(0)}</div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12, paddingBottom: 5, color: Dark.text }}>
          Pending
        </div>
        <div style={{ color: Dark.primary }}>
          {parseFloat(total).toFixed(0)}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12, paddingBottom: 5, color: Dark.text }}>
          Stop-Loss
        </div>
        <div style={{ color: Dark.sell }}>{parseFloat(sl).toFixed(0)}</div>
      </div>
    </div>
  );
}
