import React, { useEffect, useState } from "react";
import TopBar from "./TopBar";
import Search from "./Search";
import Backend from "../../../../Backend/Backend";
import Marquee from "./Marquee/Marquee";
import Watchlist1 from "./Watchlist1";
import Watchlist2 from "./Watchlist2";
import Watchlist3 from "./Watchlist3";
import Watchlist4 from "./Watchlist4";
import Watchlist5 from "./Watchlist5";
import AddSymbol from "./AddSymbol/AddSymbol";
import { Modal } from "@mui/material";
import { Dark } from "../../../../Theme/Theme";

import BuySellCard from "./BuySellCard/BuySellCard";
import { toast } from "react-toast";

const backend = new Backend();
export default function Watchlist() {
  const [select, setSelect] = useState(1);
  const [add, setAdd] = useState(false);

  const [symboldata, setSymboldata] = useState("");

  const [watchlist, setWatchlist] = useState(false);

  const [watchlist_search, setwatchlist_search] = useState(false);

  const [loading, setloading] = useState(false);

  const [modal, setmodal] = useState(false);

  useEffect(() => {
    loadWatchlist(1);
  }, []);

  const loadWatchlist = (a) => {
    setSelect(a);
    setloading(true);
    let data = {
      server: localStorage.getItem("server"),
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
      watchlist_id: a,
    };

    backend.watchlist(data).then((r) => {
      setloading(false);
      if (r.error == "False") {
        setWatchlist(r.watchlist);
        setwatchlist_search(r.watchlist);
      } else {
        if (r.logout == 1) {
          localStorage.removeItem("token");
          localStorage.removeItem("id");
          localStorage.removeItem("username");
          localStorage.removeItem("server");
          localStorage.removeItem("rules");
          localStorage.removeItem("AuthState");
          window.location = "/";
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      }
    });
  };

  const searchs = (a) => {
    let script = [];
    if (a.length > 1) {
      script = watchlist_search.filter((x) =>
        x.symbol_display.toLowerCase().includes(a.toLowerCase())
      );
      setWatchlist(script);
    } else {
      setWatchlist(watchlist_search);
    }
  };

  const closeModal = () => {
    setmodal(false);
    setSymboldata("");
  };
  const openModal = (i) => {
    setSymboldata(i);
    setmodal(true);
  };

  return (
    <div style={{ color: Dark.text }}>
      {add != true ? (
        <div>
          <Marquee />
        </div>
      ) : null}

      {add ? (
        <AddSymbol
          close={() => setAdd(false)}
          watchlist={watchlist}
          select={select}
          loadWatchlist={() => loadWatchlist(select)}
        />
      ) : (
        <>
          <TopBar
            select={select}
            update={(a) => {
              setSelect(a);
              loadWatchlist(a);
            }}
          />
          <Search update={() => setAdd(true)} search={(a) => searchs(a)} />

          {select == 1 ? (
            <Watchlist1
              loading={loading}
              watchlist={watchlist}
              openModal={openModal}
            />
          ) : null}
          {select == 2 ? (
            <Watchlist2
              loading={loading}
              watchlist={watchlist}
              openModal={openModal}
            />
          ) : null}
          {select == 3 ? (
            <Watchlist3
              loading={loading}
              watchlist={watchlist}
              openModal={openModal}
            />
          ) : null}
          {select == 4 ? (
            <Watchlist4
              loading={loading}
              watchlist={watchlist}
              openModal={openModal}
            />
          ) : null}
          {select == 5 ? (
            <Watchlist5
              loading={loading}
              watchlist={watchlist}
              openModal={openModal}
            />
          ) : null}
        </>
      )}
      <Modal
        open={modal}
        onClose={closeModal}
        className="modalview"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onBackdropClick={closeModal}
      >
        <div
          className="modalinnerview"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100vh",
            backgroundColor: "transparent",
          }}
        >
          <BuySellCard close={closeModal} symboldata={symboldata} />
        </div>
      </Modal>
    </div>
  );
}
