import React, { useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toast";
import Backend from "../../../../../Backend/Backend";
import Payment from "./Payment";
import { Dialog } from "primereact/dialog";

const backend = new Backend();

export default function Deposit(props) {
  const profile = props.profile;

  const [loading, setLoading] = useState(false);

  const [account, setAccount] = useState("");
  const [ptype, seyPType] = useState("");

  const [amount, setAmount] = useState("");

  const [pay, setPay] = useState(false);

  const AddFund = (a) => {
    if (amount <= 0) {
      toast.error("Invalid Amount !!", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      seyPType(a);
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        username: localStorage.getItem("username"),
        amount: amount,
        type: a,
      };

      backend.add_fund(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setAccount(r.account);
          setPay(true);
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  return (
    <div>
      <div
        style={{
          height: 280,
          backgroundColor: Dark.secondary,
          borderRadius: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: Dark.text,
            height: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: Dark.primary,
            paddingLeft: 15,
            paddingRight: 15,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        >
          CURRENT BALANCE - {profile.deposit_current}
        </div>
        <div
          style={{
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ paddingRight: 50, color: Dark.text }}>AMOUNT</div>
          <div>
            <InputText
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              type="number"
              placeholder="Enter Amount"
            />
          </div>
        </div>

        <div
          style={{
            height: 100,
            display: "flex",
          }}
        >
          <div
            onClick={() => {
              AddFund("0");
            }}
            style={{
              paddingRight: 100,
              color: Dark.text,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ borderRadius: 20 }}
              height={80}
              src={require("../../../../../Photos/Upi.png")}
            />
          </div>
          <div
            onClick={() => {
              AddFund("1");
            }}
            style={{
              color: Dark.text,
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ borderRadius: 20 }}
              height={80}
              src={require("../../../../../Photos/Bank.jpg")}
            />
          </div>
        </div>
      </div>

      <Dialog
        showHeader={false}
        visible={pay}
        style={{ width: "25vw", height: "60vh" }}
        onHide={() => setPay(false)}
        closeOnEscape={false}
      >
        <Payment />
      </Dialog>
    </div>
  );
}
