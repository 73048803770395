import React, { useEffect, useState } from "react";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../../Theme/Theme";
import Deposit from "./Deposit";
import Withdrowal from "./Withdrowal";

const backend = new Backend();

export default function Wallet() {
  const [profile, setProfile] = useState(false);

  const [type, setType] = useState(0);

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.profile(data).then((r) => {
      if (r.error == "False") {
        setProfile(r.profile);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div style={{ marginRight: 20, marginLeft: 20 }}>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
          fontSize: 12,
        }}
      >
        ESC TO CLOSE
      </div>

      <div
        style={{
          height: 55,
          backgroundColor: Dark.secondary,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div
            onClick={() => {
              setType(0);
            }}
            style={{
              backgroundColor: type == 0 ? Dark.buy : Dark.background,
              width: 120,
              borderRadius: 10,
              height: 40,
              borderRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderWidth: 2,
              borderColor: Dark.buy,
            }}
          >
            DEPOSIT
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          <div
            onClick={() => {
              setType(1);
            }}
            style={{
              backgroundColor: type == 1 ? Dark.sell : Dark.background,
              width: 120,
              borderRadius: 10,
              height: 40,
              borderRight: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderWidth: 2,
              borderColor: Dark.sell,
            }}
          >
            WITHDRAW
          </div>
        </div>
      </div>

      {type == 0 ? <Deposit profile={profile} /> : <Withdrowal profile={profile} />}
    </div>
  );
}
