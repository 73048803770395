import React, { useContext, useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import moment from "moment";
import SocketContext from "../../../../SocketContext/SocketContext";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
const backend = new Backend();
export default function PendingCard(props) {
  const item = props.item;

  const socket = useContext(SocketContext);
  const theme = useSelector((state) => state.theme);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [pf, setpf] = useState(0);
  const [btotal, setBtotal] = useState(0);
  const [time, setTime] = useState("");

  const sockettrade = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (item.buy_sell == 0) {
        let Profitloss = 0;
        let brokerage_total = 0;
        if (msg.Bid > 0) {
          Profitloss =
            parseFloat(parseFloat(msg.Bid) - parseFloat(item.rate)) *
            parseFloat(item.qty);

          if (item.script_type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
            );

            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.script_type == "mcx") {
            if (item.lot_volume == 1) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }
        } else {
          Profitloss = 0;
        }
        setpf(Profitloss - brokerage_total);
        setBtotal(brokerage_total);
      }
    } else {
      setbid(msg.LTP);
      setTime(msg.Timestamp);
      if (item.buy_sell == 0) {
        let Profitloss = 0;
        let brokerage_total = 0;

        if (msg.LTP > 0) {
          Profitloss =
            parseFloat(parseFloat(msg.LTP) - parseFloat(item.rate)) *
            parseFloat(item.qty);

          if (item.script_type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.script_type == "mcx") {
            if (item.lot_volume == 1) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }
        } else {
          Profitloss = 0;
        }

        setpf(Profitloss - brokerage_total);
        setBtotal(brokerage_total);
      }
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
      setTime(msg.Timestamp);
      if (item.buy_sell == 1) {
        let Profitloss2 = 0;
        let brokerage_total = 0;
        if (parseFloat(msg.Ask) > 0) {
          Profitloss2 = (item.rate - msg.Ask) * item.qty;

          if (item.script_type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.script_type == "mcx") {
            if (item.lot_volume == 1) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }
        } else {
          Profitloss2 = 0;
        }

        setpf(Profitloss2 - brokerage_total);
        setBtotal(brokerage_total);
      }
    } else {
      setask(msg.LTP);
      setTime(msg.Timestamp);
      if (item.buy_sell == 1) {
        let Profitloss2 = 0;
        let brokerage_total = 0;
        if (parseFloat(msg.LTP) > 0) {
          Profitloss2 = (item.rate - msg.LTP) * item.qty;

          if (item.script_type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.script_type == "mcx") {
            if (item.lot_volume == 1) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }
        } else {
          Profitloss2 = 0;
        }

        setpf(Profitloss2 - brokerage_total);
        setBtotal(brokerage_total);
      }
    }
  };
  const socketbidask = (msg) => {
    if (bid !== msg.Bid) {
      setbid(msg.Bid);
      if (item.buy_sell == 0) {
        let Profitloss3 = 0;
        let brokerage_total = 0;
        Profitloss3 = (msg.Bid - item.rate) * item.qty;
        if (item.script_type == "fut") {
          brokerage_total = parseFloat(
            (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
          );
          brokerage_total = brokerage_total + item.brokerage_amt;
        } else if (item.script_type == "mcx") {
          if (item.lot_volume == 1) {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }
        } else {
          brokerage_total = item.brokerage;
          brokerage_total = item.brokerage_amt * 2;
        }

        setpf(Profitloss3 - brokerage_total);
        setBtotal(brokerage_total);
      }
    }
    if (ask !== msg.Ask) {
      setask(msg.Ask);
      setTime(msg.Timestamp);
      if (item.buy_sell == 1) {
        let Profitloss4 = 0;
        let brokerage_total = 0;
        Profitloss4 = (item.rate - msg.Ask) * item.qty;
        if (item.script_type == "fut") {
          brokerage_total = parseFloat(
            (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
          );
          brokerage_total = brokerage_total + item.brokerage_amt;
        } else if (item.fut_mcx == "mcx") {
          if (item.lot_volume == 1) {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }
        } else {
          brokerage_total = item.brokerage_amt * 2;
        }

        setpf(Profitloss4 - brokerage_total);
        setBtotal(brokerage_total);
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);

    socket.on("trade" + item.symbol_id, sockettrade);

    socket.on("bidask" + item.symbol_id, socketbidask);

    return () => {
      socket.off("trade" + item.symbol_id, sockettrade);

      socket.off("bidask" + item.symbol_id, socketbidask);
    };
  }, []);

  const Cancel = () => {
    confirmAlert({
      overlayClassName: "alertLight",
      title: "Confirm to Cancel",
      message: "Are you sure Cancel.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let data = {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
              username: localStorage.getItem("username"),
              server: localStorage.getItem("server"),
              pending_id: item.id,
            };

            backend.cancel_pending(data).then((r) => {
              if (r.error == "False") {
                toast.error(r.message, {
                  backgroundColor: Dark.buy,
                  color: Dark.text,
                });
                props.load();
              } else {
                toast.error(r.message, {
                  backgroundColor: Dark.sell,
                  color: Dark.text,
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => console.log("No"),
        },
      ],
    });
  };

  return (
    <div
      onClick={Cancel}
      key={item.id}
      style={{
        height: 70,
        backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
        margin: 10,
        borderRadius: 7,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ flex: 1, paddingLeft: 10 }}>
        <div
          style={{
            color: theme == 0 ? Dark.text : Light.text,
            display: "flex",
          }}
        >
          {item.symbol}{" "}
          <div style={{ fontSize: 10, paddingLeft: 5, paddingTop: 5 }}>
            {moment(item.expiry_date).format("DD-MMM")}{" "}
          </div>
        </div>
        <div
          style={{
            color: item.limit_sl == 0 ? Dark.primary : Dark.sell,
            fontSize: 12,
            marginTop: 5,
            display: "flex",
          }}
        >
          {item.limit_sl == 0 ? "LIMIT" : "STOCK-LOSS"}
        </div>
        <div
          style={{
            color: theme == 0 ? Dark.text : Light.text,
            fontSize: 10,
            marginTop: 5,
          }}
        >
          {moment(time).format("DD-MM-YYYY hh:mm:ss")}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          paddingRight: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: item.buy_sell == 0 ? Dark.buy : Light.sell,
            fontSize: 12,
          }}
        >
          {item.price + " @"} {item.buy_sell == 0 ? "BUY" : "SELL"} {item.qty}
        </div>
        <div
          style={{
            color:
              item.status == 0
                ? Dark.text
                : item.status == 1
                ? Dark.buy
                : item.status == 2
                ? Dark.sell
                : Dark.text,
            marginTop: 5,
            fontSize: 12,
          }}
        >
          {item.msg == null ? "Waiting" : item.msg}
        </div>

        <div
          style={{
            color: Dark.text,
            fontSize: 10,
            marginTop: 5,
            display: "flex",
          }}
        >
          {item.status == 2
            ? item.msg
            : item.time_buy == null
            ? "Waiting"
            : item.time_buy}
        </div>
      </div>
    </div>
  );
}
