import React, { useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import moment from "moment";
import SocketContext from "../../../../SocketContext/SocketContext";
import { useDispatch } from "react-redux";

export default function PortfolioCard(props) {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const item = props.item;

  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [time, setTime] = useState(0);

  const [pf, setpf] = useState(0);
  const [btotal, setBtotal] = useState(0);

  const sockettrade = (msg) => {
    if (bid !== msg.Bid) {
      setTime(msg.Timestamp);
      if (msg.Bid > 0) {
        setbid(msg.Bid);
        if (item.buy_sell == 0) {
          let Profitloss = 0;
          let brokerage_total = 0;
          if (msg.Bid > 0) {
            Profitloss =
              parseFloat(parseFloat(msg.Bid) - parseFloat(item.rate)) *
              parseFloat(item.qty);

            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
                );
              } else {
                brokerage_total = item.brokerage_amt;
              }
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss = 0;
          }
          setpf(Profitloss - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    } else {
      if (msg.LTP > 0) {
        setbid(msg.LTP);

        if (item.buy_sell == 0) {
          let Profitloss = 0;
          let brokerage_total = 0;

          if (msg.LTP > 0) {
            Profitloss =
              parseFloat(parseFloat(msg.LTP) - parseFloat(item.rate)) *
              parseFloat(item.qty);

            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
                );
              } else {
                brokerage_total = item.brokerage_amt;
              }
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss = 0;
          }

          setpf(Profitloss - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    }
    if (ask !== msg.Ask) {
      setTime(msg.Timestamp);
      if (msg.Ask > 0) {
        setask(msg.Ask);
        if (item.buy_sell == 1) {
          let Profitloss2 = 0;
          let brokerage_total = 0;
          if (parseFloat(msg.Ask) > 0) {
            Profitloss2 = (item.rate - msg.Ask) * item.qty;

            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
                );
              } else {
                brokerage_total = item.brokerage_amt;
              }
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss2 = 0;
          }

          setpf(Profitloss2 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    } else {
      setTime(msg.Timestamp);
      if (msg.LTP > 0) {
        setask(msg.LTP);
        if (item.buy_sell == 1) {
          let Profitloss2 = 0;
          let brokerage_total = 0;
          if (parseFloat(msg.LTP) > 0) {
            Profitloss2 = (item.rate - msg.LTP) * item.qty;

            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
                );
              } else {
                brokerage_total = item.brokerage_amt;
              }
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss2 = 0;
          }

          setpf(Profitloss2 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    }
  };

  const socketbidask = (msg) => {
    if (bid !== msg.Bid) {
      if (msg.Bid > 0) {
        setbid(msg.Bid);
        if (item.buy_sell == 0) {
          let Profitloss3 = 0;
          let brokerage_total = 0;
          Profitloss3 = (msg.Bid - item.rate) * item.qty;
          if (item.type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.type == "mcx") {
            if (item.brokerage < 10) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
              );
            } else {
              brokerage_total = item.brokerage_amt;
            }
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }

          setpf(Profitloss3 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    }

    if (ask !== msg.Ask) {
      setTime(msg.Timestamp);
      if (msg.Ask > 0) {
        setask(msg.Ask);
        if (item.buy_sell == 1) {
          let Profitloss4 = 0;
          let brokerage_total = 0;
          Profitloss4 = (item.rate - msg.Ask) * item.qty;
          if (item.type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.fut_mcx == "mcx") {
            if (item.brokerage < 10) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
              );
            } else {
              brokerage_total = item.brokerage_amt;
            }
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }

          setpf(Profitloss4 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);

    socket.on("trade" + item.symbol_id, sockettrade);

    socket.on("bidask" + item.symbol_id, socketbidask);

    return () => {
      socket.off("trade" + item.symbol_id, sockettrade);

      socket.off("bidask" + item.symbol_id, socketbidask);
    };
  }, []);

  dispatch({
    type: "PF",
    symbol_id: item.symbol_id,
    pf: pf,
    btotal: btotal,
    total: props.total,
  });

  return (
    <div
      key={item.id}
      onClick={() => {
        props.openModal(item);
      }}
      style={{
        display: "flex",
        height: 70,
        backgroundColor: Dark.secondary,
        width: "100%",
        alignItems: "center",
        marginTop: 10,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          marginLeft: 10,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            fontSize: 12,
            color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
          }}
        >
          {item.buy_sell == 0 ? "BUY" : "SELL"} :{" "}
          {item.type == "fut"
            ? item.qty + " QTY"
            : item.qty / item.lot_size + " LOT"}
        </div>
        <div
          style={{
            display: "flex",
            fontSize: 14,
            color: Dark.text,
            marginTop: 7,
            fontWeight: "bold",
          }}
        >
          {item.symbol}{" "}
          <div style={{ fontSize: 10, marginTop: 6, marginLeft: 5 }}>
            {" "}
            {moment(item.expiry_date).format("DD-MMM")}
          </div>{" "}
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 7,
            color: Dark.text,
          }}
        >
          {moment(time).format("DD-MM-YYYY HH:mm:ss")}
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          marginLeft: 10,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <div style={{ display: "flex", fontSize: 11, color: Dark.sell }}>
          BROKERAGE : {parseFloat(btotal).toFixed(2)} ({item.brokerage})
        </div>
        <div
          style={{
            fontSize: 18,
            marginTop: 7,
            color: pf > 0 ? Dark.buy : Dark.sell,
            fontWeight: "bold",
          }}
        >
          {parseFloat(pf).toFixed(2)}
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 7,
            color: Dark.text,
            display: "flex",
          }}
        >
          <div
            style={{
              paddingRight: 5,
              color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
            }}
          >
            {item.buy_sell == 0 ? "B" : "S"} : {item.rate}
          </div>
          <div style={{ paddingRight: 5 }}>{" -> "}</div>
          <div>
            C :{" "}
            {item.buy_sell == 0
              ? parseFloat(bid).toFixed(2)
              : parseFloat(ask).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
}
