import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import ArrowTrad from "../../../../Photos/ArrowTrad.png";
import LogoSub from "../../../../Photos/SubArrowTrad.png";
import { InputText } from "primereact/inputtext";
import ReactLoading from "react-loading";
import { toast } from "react-toast";
import { AiFillPlusCircle } from "react-icons/ai";
import { Checkbox } from "primereact/checkbox";
import Backend from "../../../../Backend/Backend";
import { useSelector } from "react-redux";
import "./Login.css";
import { useNavigate } from "react-router-dom";

const backend = new Backend();
export default function Login() {
  const navigate = useNavigate();
  const theme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);

  const [server, setServer] = useState("");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);

  const [show, setShow] = useState(true);

  const [search, setSearch] = useState("");
  const [serverlist, setServerList] = useState("");

  useEffect(() => {
    if (localStorage.getItem("remember") == "1") {
      setServer(JSON.parse(localStorage.getItem("server_rem")));
      setUsername(localStorage.getItem("username_rem"));
      setPassword(localStorage.getItem("password_rem"));
      setShow(false);
      setRemember(true);
    }
  }, []);

  const onSubmit = () => {
    if (server == "") {
      toast.error("Invalid Server", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (username == "") {
      toast.error("Invalid Username", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else if (password == "") {
      toast.error("Invalid Password", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
    } else {
      setLoading(true);

      let data = {
        server: server.server,
        username: username,
        password: password,
      };

      backend.login(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          if (r.password == 0) {
            localStorage.setItem("token", r.token);
            localStorage.setItem("id", r.id);
            localStorage.setItem("server", r.server);
            navigate("/cpassword");
          } else {
            localStorage.setItem("token", r.token);
            localStorage.setItem("id", r.id);
            localStorage.setItem("username", r.username);
            localStorage.setItem("server", r.server);
            localStorage.setItem("AuthState", "1");

            let data_rem = {
              server: server,
              username: username,
              password: password,
            };

            if (remember) {
              localStorage.setItem("remember", "1");
              localStorage.setItem(
                "server_rem",
                JSON.stringify(data_rem.server)
              );
              localStorage.setItem("username_rem", data_rem.username);
              localStorage.setItem("password_rem", data_rem.password);
            } else {
              localStorage.setItem("remember", "0");
              localStorage.setItem(
                "server_rem",
                JSON.stringify(data_rem.server)
              );
              localStorage.setItem("username_rem", data_rem.username);
              localStorage.setItem("password_rem", data_rem.password);
            }
            toast.success(r.message, {
              backgroundColor: Dark.buy,
              color: Dark.text,
            });
            window.location = "/";
          }
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  const onSubmitDemo = (a, b, c) => {
    setLoading(true);

    let data = {
      server: a,
      username: b,
      password: c,
    };

    backend.login(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        localStorage.setItem("demo", "1");
        localStorage.setItem("token", r.token);
        localStorage.setItem("id", r.id);
        localStorage.setItem("username", r.username);
        localStorage.setItem("server", r.server);
        localStorage.setItem("AuthState", "1");
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        window.location = "/";
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const demo = () => {
    backend.demo().then((r) => {
      setLoading(false);
      if (r.error == "False") {
        onSubmitDemo(r.server, r.username, r.password);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };
  const _onChnageSearch = (a) => {
    setSearch(a);
    find_server(a);
  };

  const find_server = (a) => {
    if (a.length > 1) {
      setLoading(false);
      let data = {
        name: a,
      };
      backend.server(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          setServerList(r.server);
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      });
    }
  };

  onkeydown = (event) => {
    if (event.key == "Enter") {
      onSubmit();
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
      }}
    >
      <div className={theme == 0 ? "loginviewdarkweb" : "loginviewlightweb"}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 20,
          }}
        >
          <img src={ArrowTrad} style={{ height: "200px" }} />
          <img src={LogoSub} style={{ height: "25px" }} />
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: 10,
            marginRight: 20,
          }}
        >
          {show ? (
            <div
              className={
                theme == 0 ? "loginInputDarkweb" : "loginInputLightweb"
              }
            >
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  marginBottom: 7,
                  textAlign: "center",
                  fontSize: 18,
                }}
              >
                SERVER
              </div>
              <InputText
                value={search}
                onChange={(e) => {
                  _onChnageSearch(e.target.value);
                }}
                placeholder="Enter Server Name"
                autocomplete="new-password"
                style={{
                  textAlign: "center",
                }}
              />
            </div>
          ) : (
            <div
              onClick={() => {
                setShow(!show);
              }}
              style={{
                color: theme == 0 ? Dark.text : Light.text,
                marginBottom: 15,
                textAlign: "center",
                fontSize: 18,
                padding: 10,
                backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
                width: "90%",
                borderRadius: 10,
                marginTop: 10,
              }}
            >
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  marginBottom: 7,
                  textAlign: "center",
                  fontSize: 18,
                }}
              >
                SERVER
              </div>
              {server == "" || server == null || server == undefined
                ? "SELECT SERVER"
                : server.server + " (" + server.alias + ")"}
            </div>
          )}

          {search.length > 1 ? (
            <div
              className="scrolldivu"
              style={{
                height: 170,
                backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
                width: "90%",
                marginBottom: 20,
                borderRadius: 10,
                overflowY: "scroll",
              }}
            >
              {serverlist.length > 0 ? (
                serverlist.map((i) => {
                  return (
                    <div
                      onClick={() => {
                        setServer(i);
                        setSearch("");
                        setShow(false);
                      }}
                      style={{
                        margin: 10,
                        padding: 10,
                        backgroundColor:
                          theme == 0 ? Dark.background : Light.background,
                        borderRadius: 10,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          color: theme == 0 ? Dark.text : Light.text,
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            color: theme == 0 ? Dark.primary : Light.primary,
                            fontSize: 22,
                            paddingBottom: 5,
                          }}
                        >
                          {i.server}
                        </div>
                        <div>{i.alias}</div>
                      </div>
                      <div
                        style={{
                          color: theme == 0 ? Dark.text : Light.text,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <AiFillPlusCircle size={30} color={Dark.buy} />
                      </div>
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    color: theme == 0 ? Dark.text : Light.text,
                  }}
                >
                  NO SERVER FOUND !!
                </div>
              )}
            </div>
          ) : (
            <>
              <div
                className={
                  theme == 0 ? "loginInputDarkweb" : "loginInputLightweb"
                }
              >
                <div
                  style={{
                    color: theme == 0 ? Dark.text : Light.text,
                    marginBottom: 7,
                    textAlign: "center",
                    fontSize: 18,
                  }}
                >
                  USERNAME
                </div>
                <InputText
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                  style={{
                    textAlign: "center",
                  }}
                  placeholder="Enter Username"
                  autocomplete="new-password"
                />
              </div>
              <div
                className={
                  theme == 0 ? "loginInputDarkweb" : "loginInputLightweb"
                }
              >
                <div
                  style={{
                    color: theme == 0 ? Dark.text : Light.text,
                    marginBottom: 7,
                    textAlign: "center",
                    fontSize: 18,
                  }}
                >
                  PASSWORD
                </div>
                <InputText
                  value={password}
                  autoComplete="off"
                  type="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  style={{
                    textAlign: "center",
                  }}
                  placeholder="Enter Password"
                  autocomplete="new-password"
                />
              </div>
            </>
          )}

          <div
            style={{
              width: "90%",
            }}
          >
            <div
              style={{
                color: Dark.text,
                marginBottom: 10,
                width: "80%",
                fontSize: 18,
                display: "flex",
              }}
            >
              <Checkbox
                checked={remember}
                onChange={(e) => setRemember(e.checked)}
              />{" "}
              <div style={{ paddingLeft: 15 }}>Remember</div>
            </div>
          </div>

          <div
            onClick={loading ? null : onSubmit}
            className={
              theme == 0 ? "loginbuttondarkweb" : "loginbuttonlightweb"
            }
          >
            {loading ? (
              <ReactLoading
                type={"spin"}
                color={Dark.text}
                height={19}
                width={19}
              />
            ) : (
              "SUBMIT"
            )}
          </div>

          <div
            onClick={demo}
            style={{
              fontSize: 14,
              color: theme == 0 ? Dark.primary : Light.primary,
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            LOGIN WITH DEMO ACOOUNT !
          </div>

          {/* <div
              style={{
                fontSize: 14,
                color: theme == 0 ? Dark.text : Light.text,
                marginTop: 15,
                marginBottom: 15,
                display: "flex",
              }}
            >
              DON'T HAVE ACCOUNT{" "}
              <div onClick={SignUp} style={{ paddingLeft: 10 }}>
                {" "}
                SIGN UP.{" "}
              </div>
            </div> */}

          <div
            style={{
              fontSize: 10,
              color: theme == 0 ? Dark.text : Light.text,
              marginTop: 15,
              marginBottom: 15,
            }}
          >
            THIS APPLICATION IS FOR TRAINING PURPOSE ONLY.
          </div>
        </div>
      </div>
    </div>
  );
}
