import React, { useContext, useEffect, useState } from "react";
import Search from "./Search";
import Backend from "../../../../Backend/Backend";
import { Modal } from "@mui/material";
import { Dark } from "../../../../Theme/Theme";
import SocketContext from "../../../../SocketContext/SocketContext";
import BuySellCard from "./BuySellCard/BuySellCard";
import { toast } from "react-toast";
import { useDispatch, useSelector } from "react-redux";
import Loading from "react-loading";
import TopCard from "./TopCard/TopCard";
import PortfolioCard from "./PortfolioCard";

const backend = new Backend();
export default function Portfolio() {
  const show = useSelector((state) => state.show);
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);

  const [modal, setmodal] = useState(false);

  const [symboldata, setSymboldata] = useState("");

  const [position, setPosition] = useState([]);
  const [position_filter, setPosition_Filter] = useState([]);
  const [booked, setBooked] = useState(0);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    loadStanding();
    socket.on("client", client);
    return () => {
      socket.off("client", client);
    };
  }, []);

  const loadStanding = (a) => {
    setloading(true);
    let data = {
      server: localStorage.getItem("server"),
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    };

    backend.standing(data).then((r) => {
      setloading(false);
      if (r.error == "False") {
        setPosition(r.standing);
        setPosition_Filter(r.standing);
        setBalance(r.balance);
        setBooked(r.booked);
        dispatch({ type: "CLEAR_PF" });
      } else {
        if (r.logout == 1) {
          localStorage.removeItem("isLogged");
          localStorage.removeItem("token");
          localStorage.removeItem("server");
          localStorage.removeItem("client");
          localStorage.removeItem("client_details");
          window.location = "/";
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
        }
      }
    });
  };

  const search = (a) => {
    let script = [];
    if (a.length > 1) {
      script = position_filter.filter((x) =>
        x.symbol.toLowerCase().includes(a.toLowerCase())
      );
      setPosition(script);
    } else {
      setPosition(position_filter);
    }
  };

  const closeModal = () => {
    setmodal(false);
    setSymboldata("");
  };
  const openModal = (i) => {
    setSymboldata(i);
    setmodal(true);
  };

  const client = (a) => {
    if (localStorage.getItem("id") == a.id) {
      loadStanding();
    }
  };

  return (
    <div
      style={{
        backgroundColor: Dark.background,
        height: show == 0 ? window.innerHeight - 145 : window.innerHeight - 295,
      }}
    >
      <div
        style={{
          margin: 10,
          marginTop: 0,
        }}
      >
        <TopCard
          booked={booked}
          balance={balance}
          loadPosition={loadStanding}
        />
        <Search search={search} reload={loadStanding} />

        {loading ? (
          <div
            style={{
              height:
                show == 0 ? window.innerHeight - 245 : window.innerHeight - 495,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" height="40px" width="40px" />
          </div>
        ) : (
          <div style={{ height: "100%" }}>
            {position.length > 0 ? (
              <div
                style={{
                  height:
                    show == 0
                      ? window.innerHeight - 270
                      : window.innerHeight - 520,
                  overflowY: "scroll",
                }}
              >
                {position.map((i) => {
                  return (
                    <PortfolioCard
                      key={i.id}
                      item={i}
                      total={position.length}
                      openModal={openModal}
                    />
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  height:
                    show == 0
                      ? window.innerHeight - 245
                      : window.innerHeight - 495,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div style={{ fontSize: 25, color: Dark.text }}>
                  Nothing here
                </div>
                <div style={{ color: Dark.text }}>
                  Use the search bar to add instruments.
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <Modal
        open={modal}
        onClose={closeModal}
        className="modalview"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onBackdropClick={closeModal}
      >
        <div
          className="modalinnerview"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            height: "100vh",
            backgroundColor: "transparent",
          }}
        >
          <BuySellCard close={closeModal} symboldata={symboldata} />
        </div>
      </Modal>
    </div>
  );
}
