import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { BiSearch } from "react-icons/bi";
import { IoReload } from "react-icons/io5";
import { MdNotificationsNone } from "react-icons/md";
import { InputText } from "primereact/inputtext";
import './Search.css'
export default function Search(props) {
  const [search_text, setsearch_text] = useState("");
  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.secondary,
        borderRadius:10
      }}
    >
      <div
        style={{
          height: 50,
          width: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 40,
            width: 40,
            backgroundColor: Dark.secondary,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BiSearch size={25} color={Dark.text} />
        </div>
      </div>
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <InputText
          className={"searchInputPortfplio"}
          value={search_text}
          onChange={(e) => {
            setsearch_text(e.target.value);
            props.search(e.target.value);
          }}
          style={{
            backgroundColor: Dark.secondary,
            color: Dark.text,
            height: 40,
            borderStyle: "solid",
            borderColor: Dark.secondary,
            width: "100%",
          }}
          autoComplete="new-password"
          placeholder="Select Symbol "
        />
      </div>

      <div
        onClick={() => props.reload()}
        style={{
          height: 50,
          width: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: 40,
            width: 40,
            backgroundColor: Dark.secondary,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IoReload size={30} color={Dark.text} />
        </div>
      </div>
    </div>
  );
}
