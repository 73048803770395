import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import NewsCard from "./NewsCard";
import Loading from "react-loading";
import { toast } from "react-toast";
import Backend from "../../../../../Backend/Backend";
import Header from "./Header";
import { useSelector } from "react-redux";
const backend = new Backend();

export default function News(props) {
  const show = useSelector((state) => state.show);

  const [loading, setLoading] = useState(false);

  const [news, setNews] = useState([]);

  useEffect(() => {
    LoadNews();
  }, []);

  const LoadNews = () => {
    setLoading(true);
    let data = {
      server: localStorage.getItem("server"),
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    };

    backend.news(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setNews(r.news);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: show == 0 ? window.innerHeight - 145 : window.innerHeight - 295,
        backgroundColor: Dark.background,
      }}
    >
      <Header />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Loading type="spin" height="40px" width="40px" />
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          {news.length > 0 ? (
            <div
              style={{
                overflowY: "scroll",
                height: window.innerHeight - 180,
              }}
            >
              {news.map((i) => {
                return <NewsCard item={i} />;
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
                color: Dark.text,
              }}
            >
              <div style={{ fontSize: 25, paddingBottom: 5 }}>Nothing here</div>
              <div> News Not Found.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
