import React from "react";
import { Dark } from "../../../../Theme/Theme";
import WactlistCard from "./WactlistCard";
import Loading from "react-loading";
import { useSelector } from "react-redux";

export default function Watchlist1(props) {
  const show = useSelector((state) => state.show);

  return (
    <div style={{ height: "100%", backgroundColor: Dark.background }}>
      {props.loading ? (
        <div
          style={{
            height: show == 0 ? window.innerHeight - 275 : window.innerHeight - 435,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loading type="spin" height="40px" width="40px" />
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          {props.watchlist.length > 0 ? (
            <div
              style={{
                height: "100%",
              }}
            >
              <div
                style={{
                  height: show == 0 ? window.innerHeight - 275 : window.innerHeight - 435,
                  overflowY: "scroll",
                }}
              >
                {props.watchlist.map((i) => {
                  return <WactlistCard item={i} openModal={props.openModal} />;
                })}
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                height: show == 0 ? window.innerHeight - 275 : window.innerHeight - 435,
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25 }}>Nothing here</div>
              <div>Use the search bar to add instruments.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
