import React from "react";
import { Dark } from "../../../../Theme/Theme";

export default function TopBar(props) {
  const select = props.select;
  return (
    <div
      style={{
        height: 55,
        display: "flex",
        overflowX: "scroll",
        width: window.innerWidth,
        backgroundColor: Dark.background,
      }}
    >
      <div
        onClick={() => props.update(1)}
        style={{
          height: 55,
          width: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: select == 1 ? Dark.primary : Dark.text,
          fontSize: 12,
          borderStyle:"solid",
          borderWidth:0,
          borderBottomWidth: 2,
          borderBottomColor:select == 1 ? Dark.primary : Dark.background
        }}
      >
        Watchlist 1
      </div>
      <div
        onClick={() => props.update(2)}
        style={{
          height: 55,
          width: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: select == 2 ? Dark.primary : Dark.text,
          fontSize: 12,
          borderStyle:"solid",
          borderWidth:0,
          borderBottomWidth: 2,
          borderBottomColor:select == 2 ? Dark.primary : Dark.background
        }}
      >
        Watchlist 2
      </div>
      <div
        onClick={() => props.update(3)}
        style={{
          height: 55,
          width: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: select == 3 ? Dark.primary : Dark.text,
          fontSize: 12,
          borderStyle:"solid",
          borderWidth:0,
          borderBottomWidth: 2,
          borderBottomColor:select == 3 ? Dark.primary : Dark.background
        }}
      >
        Watchlist 3
      </div>
      <div
        onClick={() => props.update(4)}
        style={{
          height: 55,
          width: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: select == 4 ? Dark.primary : Dark.text,
          fontSize: 12,
          borderStyle:"solid",
          borderWidth:0,
          borderBottomWidth: 2,
          borderBottomColor:select == 4 ? Dark.primary : Dark.background
        }}
      >
        Watchlist 4
      </div>
      <div
        onClick={() => props.update(5)}
        style={{
          height: 55,
          width: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: select == 5 ? Dark.primary : Dark.text,
          fontSize: 12,
          borderStyle:"solid",
          borderWidth:0,
          borderBottomWidth: 2,
          borderBottomColor:select == 5 ? Dark.primary : Dark.background
        }}
      >
        Watchlist 5
      </div>
    </div>
  );
}
