import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { Calendar } from "primereact/calendar";
import moment from "moment";
export default function Filter(props) {
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  return (
    <div>
      <div
        style={{
          height: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        FILTER
      </div>
      <div
        onClick={() => {
          props.load(new Date(), new Date());
          props.close();
        }}
        style={{
          height: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.primary,
            width: "80%",
            textAlign: "center",
            borderRadius: 10,
          }}
        >
          TODAY
        </div>
      </div>
      <div
        onClick={() => {
          props.load(
            moment().subtract(0, "weeks").startOf("week").format(),
            moment().subtract(0, "weeks").endOf("week").format()
          );
          props.close();
        }}
        style={{
          height: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.primary,
            width: "80%",
            textAlign: "center",
            borderRadius: 10,
          }}
        >
          THIS WEEK
        </div>
      </div>
      <div
        onClick={() => {
          props.load(
            moment().subtract(1, "weeks").startOf("week").format(),
            moment().subtract(1, "weeks").endOf("week").format()
          );
          props.close();
        }}
        style={{
          height: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.primary,
            width: "80%",
            textAlign: "center",
            borderRadius: 10,
          }}
        >
          LEST WEEK
        </div>
      </div>
      <div
        style={{
          height: 70,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <div style={{ textAlign: "center" }}>
          <div>START</div>
          <div>
            <Calendar
              dateFormat="dd-mm-yy"
              style={{ width: 120 }}
              value={start}
              onChange={(e) => {
                setStart(e.value);
              }}
            />
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <div>END</div>
          <div>
            <Calendar
              dateFormat="dd-mm-yy"
              style={{ width: 120 }}
              value={end}
              onChange={(e) => setEnd(e.value)}
            />
          </div>
        </div>
      </div>

      <div
        onClick={() => {
          props.load(start, end);
          props.close();
        }}
        style={{
          height: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.primary,
            width: "80%",
            textAlign: "center",
            borderRadius: 10,
          }}
        >
          SUBMIT
        </div>
      </div>
    </div>
  );
}
