import React from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import { useSelector } from "react-redux";

export default function TopCard(props) {
  const theme = useSelector((state) => state.theme);
  let brokerage = props.brokerage;
  let profitloss = props.profitloss;

  let m2m = parseFloat(parseFloat(profitloss) + parseFloat(brokerage)).toFixed(
    2
  );

  return (
    <div
      style={{
        height: 55,
        backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginRight: 10,
        marginLeft: 10,
        marginTop:10
      }}
    >
      <div
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          width: "100%",
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: "center",
            color: Dark.sell,
            fontSize: 12,
          }}
        >
          BROKRAGE : {parseFloat(brokerage).toFixed(2)}
        </div>
        <div
          style={{
            flex: 1,
            textAlign: "center",
            color: m2m > 0 ? Dark.buy : Dark.sell,
            fontSize: 12,
          }}
        >
          M2M : {parseFloat(m2m).toFixed(2)}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: profitloss > 0 ? Dark.buy : Dark.sell,
            fontSize: 12,
          }}
        >
          {" "}
          PROFIT/LOSS :{profitloss}
        </div>
      </div>
    </div>
  );
}
