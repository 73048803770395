import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import { useSelector } from "react-redux";

export default function TopCard(props) {
  const total = useSelector((state) => state.total);
  const btotal = useSelector((state) => state.btotal);

  let booked = props.booked;
  let balance = props.balance;

  let m2m = parseFloat(parseFloat(total) + parseFloat(btotal)).toFixed(2);

  return (
    <div
      style={{
        height: "12vh",
        backgroundColor: Dark.secondary,
        borderRadius: 10,
        marginBottom: 10,
        display: "flex",
        textTransform: "uppercase",
        fontWeight:"bold"
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: Dark.text,
              fontSize: 12,
            }}
          >
            BROKERAGE
          </div>
          <div
            style={{
              color: Dark.sell,
              fontSize: 16,
              paddingTop: 5,
            }}
          >
            {parseFloat(btotal).toFixed(0)}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: Dark.text,
              fontSize: 12,
            }}
          >
            M2M
          </div>
          <div
            style={{
              color: m2m > 0 ? Dark.buy : Dark.sell,
              fontSize: 16,
              paddingTop: 5,
            }}
          >
            {parseFloat(m2m).toFixed(2)}
          </div>
        </div>
      </div>
      <div
        style={{
          flex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: Dark.text,
            fontSize: 12,
          }}
        >
          LIVE P&L
        </div>
        <div
          style={{
            color: total > 0 ? Dark.buy : Dark.sell,
            fontSize: 18,
            paddingTop: 5,
          }}
        >
          {parseFloat(total).toFixed(2)}
        </div>
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: Dark.text,
              fontSize: 12,
            }}
          >
            BOOKED P&L
          </div>
          <div
            style={{
              color: booked > 0 ? Dark.buy : Dark.sell,
              fontSize: 16,
              paddingTop: 5,
            }}
          >
            {booked == null ? "0" : parseFloat(booked).toFixed(2)}
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: Dark.text,
              fontSize: 12,
            }}
          >
            NET P&L
          </div>
          <div
            style={{
              color: booked + total > 0 ? Dark.buy : Dark.sell,
              fontSize: 16,
              paddingTop: 5,
            }}
          >
            {parseFloat(booked + total).toFixed(2)}
          </div>
        </div>
      </div>
    </div>
  );
}
