import React, { useRef, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { InputText } from "primereact/inputtext";
import { BiFilterAlt } from "react-icons/bi";
import { IoSearchSharp } from "react-icons/io5";
import { Dialog } from "primereact/dialog";
import Filter from "./Filter";
export default function Search(props) {
  const [show, setShow] = useState(false);

  return (
    <div
      style={{
        height: 50,
        backgroundColor: Dark.secondary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        marginRight: 10,
        marginLeft: 10,
      }}
    >
      <div
        style={{
          height: 40,
          width: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Dark.secondary,
          marginRight: 10,
          marginLeft: 10,
          borderRadius: 10,
        }}
      >
        <IoSearchSharp size={25} color={Dark.text} />
      </div>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <InputText
          className={"orderinput"}
          onChange={(e) => {
            props.search(e.target.value);
          }}
          style={{
            backgroundColor: Dark.secondary,
            color: Dark.text,
            height: 40,
          }}
          placeholder="Search Symbol"
        />
      </div>
      <div
        onClick={() => {
          setShow(true);
        }}
        style={{
          height: 40,
          width: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Dark.secondary,
          marginRight: 10,
          marginLeft: 10,
          borderRadius: 10,
        }}
      >
        <BiFilterAlt size={25} color={Dark.text} />
      </div>

      <Dialog
        visible={show}
        showHeader={false}
        style={{ width: "90vw", height: "50vh" }}
        onHide={() => setShow(false)}
      >
        <Filter load={props.load} close={() => setShow(false)} />
      </Dialog>
    </div>
  );
}
