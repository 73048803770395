import React, { useEffect, useState } from "react";
import Marquees from "react-fast-marquee";
import { Dark, Light } from "../../../../../Theme/Theme";
import Backend from "../../../../../Backend/Backend";
import { toast } from "react-toast";
import { useSelector } from "react-redux";
import Rules from "../Rules/Rules";
import { Dialog } from "primereact/dialog";
const backend = new Backend();

export default function Marquee() {
  const theme = useSelector((state) => state.theme);

  const [marquees, setMarquee] = useState("");
  const [rules, setRules] = useState(false);

  useEffect(() => {
    load_marquee();
  }, []);

  const load_marquee = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
    };

    backend.load_marquee(data).then((r) => {
      if (r.error == "False") {
        setMarquee(r.marquee.text);
        if (localStorage.getItem("rules") == 1) {
        } else {
          setRules(true);
        }
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 25,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: Dark.background,

      }}
    >
      <Marquees
        style={{
          fontSize: 14,
          color: theme == 0 ? Dark.text : Light.text,
          backgroundColor: Dark.secondary,
          borderRadius: 30,
          height: 25,
          width:"100%"
        }}
      >
        {marquees == undefined || marquees == null || marquees == ""
          ? "NO HEADLINE FOUND"
          : marquees}
      </Marquees>

      <Dialog
        showHeader={false}
        visible={rules}
        style={{ width: "95%", height: "95%" }}
        onHide={() => setRules(false)}
      >
        <Rules close={() => setRules(false)} />
      </Dialog>
    </div>
  );
}
