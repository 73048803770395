import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SocketContect from "./Components/SocketContext/SocketContext";
import { Dark, Light } from "./Components/Theme/Theme";
import { ToastContainer } from "react-toast";
import io from "socket.io-client";
import { useSelector } from "react-redux";
import { BrowserView, MobileView } from "react-device-detect";
import useWindowSize from "./Components/Healer/WindowSize";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./App.css";

import NiftyBankNifty from "./Components/Screen/Web/MainStack/NiftyBankNifty/NiftyBankNifty";
import TopView from "./Components/Screen/Web/MainStack/TopView/TopView";
import Login from "./Components/Screen/Web/LoginStack/Login/Login";
import CPassword from "./Components/Screen/Web/LoginStack/Password/CPassword";
import Watchlist from "./Components/Screen/Web/MainStack/Watchlist/Watchlist";
import Standing from "./Components/Screen/Web/MainStack/Position/Position";
import Pending from "./Components/Screen/Web/MainStack/Pending/Pending";
import Order from "./Components/Screen/Web/MainStack/Order/Order";

import LoginMobile from "./Components/Screen/Mobile/LoginMobileStack/Login/Login";
import PasswordMobile from "./Components/Screen/Mobile/LoginMobileStack/Password/Password";

import NiftyBankMobile from "./Components/Screen/Mobile/MobileStack/NiftyBankNifty/NiftyBankNifty";
import BottomBar from "./Components/Screen/Mobile/MobileStack/BottomBar/BottomBar";

import MobileWatchlist from "./Components/Screen/Mobile/MobileStack/Watchlist/Watchlist";
import MobileStanding from "./Components/Screen/Mobile/MobileStack/Portfolio/Portfolio";

import MobilePending from "./Components/Screen/Mobile/MobileStack/Pending/Pending";
import MobileOrder from "./Components/Screen/Mobile/MobileStack/Order/Order";

// import MobileSymbolEdit from "./Component/Screen/MobileStack/Watclist/SymbolEdit/SymbolEdit";

import MobileProfile from "./Components/Screen/Mobile/MobileStack/Profile/Profile";

import MobileNews from "./Components/Screen/Mobile/MobileStack/Profile/News/News";

const socket = io("https://arrowtrad.com:50000");

export default function App() {
  const height = useWindowSize();
  const theme = useSelector((state) => state.theme);

  return (
    <div
      style={{
        height: height,
        backgroundColor: theme == 0 ? Dark.background : Light.background,
      }}
    >
      <BrowserView>
        <div
          style={{
            backgroundColor: theme == 0 ? Dark.secondary : Light.secoundry,
            height: height,
          }}
        >
          <ToastContainer
            className="toplevel"
            position="top-right"
            delay={3000}
          />
          {localStorage.getItem("AuthState") == "1" ? (
            <BrowserRouter>
              <SocketContect.Provider value={socket}>
                <NiftyBankNifty />
                <TopView />
                <div>
                  <Routes>
                    <Route path="/" element={<Watchlist />} />
                    <Route path="*" element={<Watchlist />} />
                    <Route path="/Watchlist" element={<Watchlist />} />
                    <Route path="/Standing" element={<Standing />} />
                    <Route path="/Pending" element={<Pending />} />
                    <Route path="/Trade" element={<Order />} />
                  </Routes>
                </div>
              </SocketContect.Provider>
            </BrowserRouter>
          ) : (
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="*" element={<Login />} />
                <Route path="/Login" element={<Login />} />
                <Route path="/CPassword" element={<CPassword />} />
              </Routes>
            </BrowserRouter>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div style={{ backgroundColor: Dark.secondary }}>
          <ToastContainer
            className="toplevel"
            position="top-right"
            delay={3000}
          />
          {localStorage.getItem("AuthState") == "1" ? (
            <BrowserRouter>
              <SocketContect.Provider value={socket}>
                <NiftyBankMobile />
                <Routes>
                  <Route path="*" element={<MobileWatchlist />} />
                  <Route path="/" element={<MobileWatchlist />} />
                  <Route path="/Watchlist" element={<MobileWatchlist />} />
                  <Route path="/Standing" element={<MobileStanding />} />
                  <Route path="/Pending" element={<MobilePending />} />
                  <Route path="/Trade" element={<MobileOrder />} />
                  <Route path="/Profile" element={<MobileProfile />} />
                  <Route path="/News" element={<MobileNews />} />
                </Routes>
                <BottomBar />
              </SocketContect.Provider>
            </BrowserRouter>
          ) : (
            <BrowserRouter>
              <Routes>
                <Route path="*" element={<LoginMobile />} />
                <Route path="/" element={<LoginMobile />} />
                <Route path="Login" element={<LoginMobile />} />
                <Route path="Cpassword" element={<PasswordMobile />} />
              </Routes>
            </BrowserRouter>
          )}
        </div>
      </MobileView>
    </div>
  );
}
