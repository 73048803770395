import React from "react";
import { Dark } from "../../../../../Theme/Theme";

export default function NewsCard(props) {
  const item = props.item;
  return (
    <div
      style={{
        margin: 15,
        backgroundColor: Dark.secondary,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          padding: 10,
          fontWeight: "bold",
          textTransform: "uppercase",
          color:
            item.type == 0
              ? Dark.primary
              : item.type == 1
              ? Dark.buy
              : item.type == 2
              ? Dark.primary
              : Dark.sell,
        }}
      >
        {item.title}
      </div>
      <div style={{ padding: 10, color: Dark.text }}>{item.msg}</div>
    </div>
  );
}
