import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import TopCard from "./TopCard/TopCard";
import Menu from "./Menu";
import PendingCard from "./PendingCard/PendingCard";
import { toast } from "react-toast";
import Backend from "../../../../Backend/Backend";
import Loading from "react-loading";
import { useSelector } from "react-redux";

const backend = new Backend();
export default function Pending() {
  const theme = useSelector((state) => state.theme);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([]);
  const [limit, setLimit] = useState(0);
  const [total, setTotal] = useState(0);
  const [sl, setSl] = useState(0);

  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());

  useEffect(() => {
    loadPending();
  }, []);

  const loadPending = (a,b) => {
    setStart(a);
    setEnd(b);
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      start: a == undefined ? new Date() : a,
      end: b == undefined ? new Date() : b,
    };

    backend.pending(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setPending(r.pending);
        setLimit(r.limit);
        setTotal(r.pendinglimit);
        setSl(r.sl);
      } else {
        toast(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: "81vh",
        backgroundColor: theme == 0 ? Dark.background : Light.background,
      }}
    >
      <div style={{ backgroundColor: Dark.background }}>
        <TopCard
          limit={limit}
          total={total}
          sl={sl}
          start={start}
          end={end}
          filter={loadPending}
        />
        <Menu />
        <div
          className="scrolldivu"
          style={{
            overflowY: "scroll",
            height: "68vh",
          }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" color={Dark.text} height={35} width={35} />
            </div>
          ) : pending.length > 0 ? (
            pending.map((i, t) => {
              return <PendingCard key={i.id} item={i} load={loadPending} />;
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Pending Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
