import React, { useState } from "react";
import { Dark, Light } from "../../../../../Theme/Theme";
import { AiFillPlusCircle, AiFillCheckCircle } from "react-icons/ai";
import moment from "moment/moment";
import { useSelector } from "react-redux";

export default function SymbolCard(props) {
  const i = props.item;
  const count = props.count;
  const index = props.index;

  const theme = useSelector((state) => state.theme);

  return (
    <div
      onClick={() => props.add_symbol(i)}
      key={i.id}
      style={{
        display: "flex",
        height: 50,
        backgroundColor:
          count == index
            ? Dark.buy
            : theme == 0
            ? Dark.background
            : Light.background,
        paddingLeft: 10,
        marginBottom: 10,
        alignItems: "center",
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          borderRadius: 10,
          color: theme == 0 ? Dark.text : Light.text,
          flexDirection: "column",
        }}
      >
        <div> {i.symbol_display}</div>
        <div style={{ fontSize: 12 }}>
          {moment(i.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          paddingLeft: 10,
          fontSize: 12,
          flex: 1,
          textAlign: "end",
          paddingRight: 10,
        }}
      >
        {/* {show ? (
          <AiFillCheckCircle
            size={30}
            color={theme == 0 ? Dark.primary : Light.primary}
          />
        ) : (
          <AiFillPlusCircle
            size={30}
            color={count == index ? Dark.text : Dark.buy}
          />
        )} */}

        <AiFillPlusCircle
          size={30}
          color={count == index ? Dark.text : Dark.buy}
        />
      </div>
    </div>
  );
}
