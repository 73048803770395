import React, { Fragment, useContext, useEffect, useState } from "react";
import useSound from "use-sound";
import { BiChevronDown } from "react-icons/bi";
import Errors from "../../../../../Sound/error.mp3";
import Buy from "../../../../../Sound/buy.mp3";
import Sell from "../../../../../Sound/sell.mp3";
import SocketContext from "../../../../../SocketContext/SocketContext";
import Backend from "../../../../../Backend/Backend";
import { InputText } from "primereact/inputtext";
import { Dark, Light } from "../../../../../Theme/Theme";
import { useSelector } from "react-redux";
import Loading from "react-loading";
import moment from "moment";
import { toast } from "react-toast";

const backend = new Backend();

function BuySellCard(props) {
  const theme = useSelector((state) => state.theme);
  const i = props.symboldata;
  const socket = useContext(SocketContext);

  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high2, sethigh2] = useState(0);
  const [low2, setlow2] = useState(0);
  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpen] = useState(0);
  const [close, setClose] = useState(0);

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(0);
  const [qty_order, setQty_order] = useState("");
  const [price, setprice] = useState("");

  const [standing, setStadning] = useState([]);
  const [symbol, setSymbol] = useState("");

  const [playSoundError] = useSound(Errors);
  const [playSoundBuy] = useSound(Buy);
  const [playSoundSell] = useSound(Sell);

  useEffect(() => {
    symbols();
    loadStanding();
    socket.emit("giverate", i.symbol_id);
    socket.on("trade" + i.symbol_id, trade);
    socket.on("bidask" + i.symbol_id, bidask);
    return () => {
      socket.off("trade" + i.symbol_id, trade);
      socket.off("bidask" + i.symbol_id, bidask);
    };
  }, []);

  const trade = (msg) => {
    if (msg == null) {
      // alert.error("Script is expire no trading available");
    } else {
      if (bid !== msg.Bid) {
        if (msg.Bid > 0) {
          setbid(msg.Bid);
        }
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > 0) {
          setask(msg.Ask);
        }
      }
      if (high2 !== msg.High) {
        sethigh2(msg.High);
      }
      if (low2 !== msg.Low) {
        setlow2(msg.Low);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        if (msg.LTP > 0) {
          setltp(msg.LTP);
        }
      }
      if (open !== msg.Open) {
        setOpen(msg.Open);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
    }
  };
  const bidask = (msg) => {
    if (bid !== msg.Bid) {
      if (msg.Bid > 0) {
        setbid(msg.Bid);
      }
    }
    if (ask !== msg.Ask) {
      if (msg.Ask > 0) {
        setask(msg.Ask);
      }
    }
  };

  const loadStanding = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      symbol_id: i.symbol_id,
    };

    backend.symbol_standing(data).then((r) => {
      if (r.error == "False") {
        setStadning(r.standing);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const symbols = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      type: i.type,
      symbol_id: i.symbol_id,
    };
    backend.symbol(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setSymbol(r.symbol);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Buy_Sell = (a) => {
    if (qty_order < 0 || qty_order == "") {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        qty_order: qty_order,
        symbol_id: i.symbol_id,
        symbol: i.symbol_display,
        symbol_type: i.type,
        type: a,
      };

      backend.trade(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          setQty_order("");
          setprice("");
          setType(0);
          loadStanding();
          if (a == 0) {
            buy();
          } else {
            sell();
          }
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const Limit = (a) => {
    if (qty_order < 0) {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else if (price == "") {
      toast.error("Invalid Order Price !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        qty_order: qty_order,
        symbol_id: i.symbol_id,
        symbol: i.symbol_display,
        symbol_type: i.type,
        type: a,
        price: price,
      };

      backend.limit(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          if (a == 0) {
            buy();
          } else {
            sell();
          }
          setType(0);
          setQty_order("");
          setprice("");
          loadStanding();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const StopLoss = (a) => {
    if (qty_order < 0 || qty_order == "") {
      toast.error("Invalid Order Qty !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else if (price == "") {
      toast.error("Invalid Order Price !", {
        backgroundColor: Dark.sell,
        color: Dark.text,
      });
      error();
    } else {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
        server: localStorage.getItem("server"),
        qty_order: qty_order,
        symbol_id: i.symbol_id,
        symbol: i.symbol_display,
        symbol_type: i.type,
        type: a,
        price: price,
      };

      backend.stoploss(data).then((r) => {
        setLoading(false);
        if (r.error == "False") {
          toast.success(r.message, {
            backgroundColor: Dark.buy,
            color: Dark.text,
          });
          if (a == 0) {
            buy();
          } else {
            sell();
          }
          setType(0);
          setQty_order("");
          setprice("");
          loadStanding();
        } else {
          toast.error(r.message, {
            backgroundColor: Dark.sell,
            color: Dark.text,
          });
          error();
        }
      });
    }
  };

  const buy = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundBuy();
    }
  };
  const sell = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundSell();
    }
  };
  const error = () => {
    if (localStorage.getItem("sound") == 1) {
      playSoundError();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        height: "88%",
        width: "100%",
        flexDirection: "column",
        backgroundColor: Dark.background,
        boxShadow: "1px 2px 9px #000000 ",
      }}
    >
      <div
        onClick={() => props.close()}
        style={{
          height: 40,
          backgroundColor: Dark.background,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <BiChevronDown color={Dark.text} size={30} />
      </div>
      <div
        style={{
          height: 120,
          backgroundColor: Dark.secondary,
          margin: 10,
          borderRadius: 10,
          display: "flex",
        }}
      >
        <div
          style={{
            color: Dark.text,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            paddingLeft: 20,
          }}
        >
          <div style={{ color: Dark.buy }}>
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: 18, color: Dark.text }}>
                {i.symbol_display}
              </div>
              <div
                style={{
                  fontSize: 10,
                  color: Dark.text,
                  paddingTop: 7,
                  paddingLeft: 5,
                }}
              >
                {moment(i.expiry_date).format("DD-MMM")}
              </div>
            </div>
            <div style={{ display: "flex", paddingTop: 7 }}>
              <div style={{ fontSize: 12, color: Dark.text }}>LTP : {ltp}</div>
              <div
                style={{
                  fontSize: 12,
                  color: chng > 0 ? Dark.buy : Dark.sell,
                  paddingLeft: 10,
                }}
              >
                {parseFloat(chng).toFixed(2)}{" "}
                {"(" + parseFloat(percent).toFixed(2) + "%)"}
              </div>
            </div>
            {i.type == "fut" ? null : (
              <div style={{ display: "flex", paddingTop: 7 }}>
                <div style={{ fontSize: 12, color: Dark.text }}>
                  LOT SIZE : {symbol.lot_size}
                </div>
              </div>
            )}

            {standing.length > 0 ? (
              <div style={{ display: "flex", paddingTop: 7 }}>
                <div
                  style={{
                    fontSize: 12,
                    color: standing[0].buy_sell == 0 ? Dark.buy : Dark.sell,
                  }}
                >
                  STANDING
                </div>
                <div
                  style={{
                    fontSize: 12,
                    color: standing[0].buy_sell == 0 ? Dark.buy : Dark.sell,
                    paddingLeft: 10,
                  }}
                >
                  {standing[0].buy_sell == 0 ? "BUY" : "SELL"} :{" "}
                  {i.type == "fut"
                    ? standing[0].qty
                    : standing[0].qty / i.lot_size}{" "}
                  {i.type == "fut" ? null : "LOT"}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div
          style={{
            color: Dark.text,
            width: 100,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div style={{ color: Dark.buy }}>
            <div style={{ fontSize: 12 }}>HIGH</div>
            <div style={{ fontSize: 22, paddingTop: 3 }}>{high2}</div>
          </div>
          <div style={{ color: Dark.sell }}>
            <div style={{ fontSize: 12 }}>LOW</div>
            <div style={{ fontSize: 22, paddingTop: 3 }}>{low2}</div>
          </div>
        </div>
      </div>
      <div
        style={{
          height: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setType(0)}
            style={{
              height: 35,
              width: 90,
              backgroundColor:
                type == 0
                  ? Dark.primary
                  : theme == 0
                  ? Dark.background
                  : Light.background,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderColor: Dark.primary,
              color:
                theme == 0
                  ? Dark.text
                  : type == 0
                  ? Dark.text
                  : Dark.background,
              fontSize: 12,
            }}
          >
            MARKET
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setType(1)}
            style={{
              height: 35,
              width: 90,
              backgroundColor:
                type == 1
                  ? Dark.primary
                  : theme == 0
                  ? Dark.background
                  : Light.background,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderColor: Dark.primary,
              color:
                theme == 0
                  ? Dark.text
                  : type == 1
                  ? Dark.text
                  : Dark.background,
              fontSize: 12,
            }}
          >
            LIMIT
          </div>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            onClick={() => setType(2)}
            style={{
              height: 35,
              width: 90,
              backgroundColor:
                type == 2
                  ? Dark.primary
                  : theme == 0
                  ? Dark.background
                  : Light.background,
              borderRadius: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderStyle: "solid",
              borderColor: Dark.primary,
              color:
                theme == 0
                  ? Dark.text
                  : type == 2
                  ? Dark.text
                  : Dark.background,
              fontSize: 12,
            }}
          >
            STOPLOSS
          </div>
        </div>
      </div>

      <div
        style={{
          height: 55,
          display: "flex",
          marginBottom: 10,
          backgroundColor: theme == 0 ? Dark.background : Light.background,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme == 0 ? Dark.text : Light.text,
            fontSize: 14,
          }}
        >
          {i.type == "fut" ? "Qty" : "Lot"}
        </div>
        <div
          style={{
            flex: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <InputText
            placeholder={i.type == "fut" ? "Enter Order Qty" : "Enter Lot"}
            value={qty_order}
            onChange={(e) => {
              if (e.target.value == "-" || e.target.value == "+") {
                setQty_order("");
              } else {
                setQty_order(e.target.value);
              }
            }}
            type="number"
            inputmode="numeric"
            style={{
              backgroundColor: theme == 0 ? Dark.secondary : Light.secondary,
              color: theme == 0 ? Dark.text : Light.text,
              height: 40,
              fontSize: 14,
              borderStyle: "solid",
              borderColor: Dark.background,
              width: "90%",
              textAlign:"center"
            }}
          />
        </div>
      </div>
      {type != 0 ? (
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: theme == 0 ? Dark.text : Light.text,
              fontSize: 14,
            }}
          >
            Price
          </div>
          <div
            style={{
              flex: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InputText
              id="inputtext"
              placeholder="Enter Order Price"
              value={price}
              onChange={(e) => {
                if (e.target.value == "-" || e.target.value == "+") {
                  setprice("");
                } else {
                  setprice(e.target.value);
                }
              }}
              type="number"
              style={{
                backgroundColor: theme == 0 ? Dark.secondary : Light.secondary,
                color: theme == 0 ? Dark.text : Light.text,
                height: 40,
                fontSize: 14,
                borderStyle: "solid",
                borderColor: Dark.background,
                width: "90%",
                textAlign:"center"
              }}
            />
          </div>
        </div>
      ) : null}

      <div
        style={{
          height: 55,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme == 0 ? Dark.background : Light.background,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
            padding: 10,
            flex: 1,
          }}
        >
          {i.type == "fut" ? (
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  borderRadius: 10,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: Dark.text,
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    color: theme == 0 ? Dark.text : Light.text,
                    fontSize: 10,
                  }}
                >
                  MIN/ORDER
                </div>

                <div
                  style={{
                    color: theme == 0 ? Dark.text : Light.text,
                    fontSize: 16,
                  }}
                >
                  {symbol == "" || symbol == null ? 0 : symbol.min_qty}
                </div>
              </div>
            </div>
          ) : null}

          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: Dark.text,
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  fontSize: 10,
                }}
              >
                {i.type == "fut" ? "Per Order Max" : "Per Order Max Lot"}
              </div>
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  fontSize: 16,
                }}
              >
                {symbol == "" || symbol == null ? 0 : i.type == "fut"
                  ? symbol.qty_per_order
                  : symbol.qty_per_order / symbol.lot_size}
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  fontSize: 10,
                }}
              >
                {i.type == "fut" ? "Total Order Max" : "Total Order Max Lot"}
              </div>
              <div
                style={{
                  color: theme == 0 ? Dark.text : Light.text,
                  fontSize: 16,
                }}
              >
                {symbol == "" || symbol == null ? 0 : i.type == "fut"
                  ? symbol.total_quantity
                  : symbol.total_quantity / symbol.lot_size}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme == 0 ? Dark.background : Light.background,
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
            paddingLeft: 10,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              onClick={() =>
                loading
                  ? null
                  : type == 0
                  ? Buy_Sell("1")
                  : type == 1
                  ? Limit("1")
                  : type == 2
                  ? StopLoss("1")
                  : null
              }
              style={{
                height: 45,
                backgroundColor: Dark.sell,
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "120px",
              }}
            >
              {loading ? (
                <Loading height={"35px"} width={"35px"} />
              ) : (
                <Fragment>
                  <div
                    style={{
                      fontSize: 12,
                      lineHeight: 1,
                      color: Dark.text,
                    }}
                  >
                    SELL
                  </div>
                  <div
                    style={{
                      fontSize: 18,
                      lineHeight: 1,
                      color: Dark.text,
                      paddingTop: 5,
                    }}
                  >
                    {bid}
                  </div>
                </Fragment>
              )}
            </div>

            <div
              onClick={() =>
                loading
                  ? null
                  : type == 0
                  ? Buy_Sell("0")
                  : type == 1
                  ? Limit("0")
                  : type == 2
                  ? StopLoss("0")
                  : null
              }
              style={{
                height: 45,
                backgroundColor: Dark.buy,
                borderRadius: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "120px",
              }}
            >
              {loading ? (
                <Loading height={"35px"} width={"35px"} />
              ) : (
                <Fragment>
                  <div
                    style={{
                      fontSize: 12,
                      lineHeight: 1,
                      color: Dark.text,
                    }}
                  >
                    BUY
                  </div>
                  <div
                    style={{
                      fontSize: 18,
                      lineHeight: 1,
                      color: Dark.text,
                      paddingTop: 5,
                    }}
                  >
                    {ask}
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuySellCard;
