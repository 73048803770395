import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import TopCard from "./TopCard";
import OrderCard from "./OrderCard";
import Header from "./Header";
import { useSelector } from "react-redux";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";

const backend = new Backend();

export default function Order() {
  const show = useSelector((state) => state.show);
  const height = show == 0 ? window.innerHeight - 145 : window.innerHeight - 295 ;
  const theme = useSelector((state) => state.theme);

  const [loading, setLoading] = useState(false);
  const [refreshing] = useState(false);

  const [brokerage, setBrokerage] = useState(0);
  const [profitloss, setProfitLoss] = useState(0);

  const [order, setOrder] = useState([]);
  const [order_search, setOrder_Search] = useState([]);

  useEffect(() => {
    LoadOrder();
  }, []);

  const LoadOrder = (a, b) => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      start: a == undefined ? new Date() : a,
      end: b == undefined ? new Date() : b,
    };

    backend.order(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setOrder(r.order);
        setOrder_Search(r.order);
        setBrokerage(r.brokerage);
        setProfitLoss(r.profitloss);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const search = (a) => {
    if (a.length > 1) {
      var data = order_search.filter((x) =>
        x.symbol.toLowerCase().includes(a.toLowerCase())
      );
      setOrder(data);
    } else {
      setOrder(order_search);
    }
  };

  return (
    <div
      style={{
        height: height,
        backgroundColor: Dark.background,
        color: Dark.text,
      }}
    >
      <Header load={(a, b) => LoadOrder(a, b)} search={(a) => search(a)} />
      <TopCard brokerage={brokerage} profitloss={profitloss} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: height - 110,
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading height={30} width={30} type={"spin"} color={Dark.text} />
          </div>
        ) : order.length > 0 ? (
          <div>
            {order.map((item) => {
              return <OrderCard item={item} />;
            })}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontSize: 22,
                color: theme == 0 ? Dark.text : Light.text,
              }}
            >
              Nothing here
            </div>
            <div
              style={{
                fontSize: 12,
                color: theme == 0 ? Dark.text : Light.text,
                paddingTop: 10,
              }}
            >
              No Order Found.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
