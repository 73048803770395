import React from "react";
import { Dark } from "../../../../../Theme/Theme";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
export default function Header() {
  return (
    <div
      style={{
        height: 55,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Link
        className="linkdarkmobile"
        to={"/"}
        style={{
          width: 55,
          height: 60,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MdKeyboardArrowDown style={{ fontSize: 30, color: Dark.text }} />
      </Link>
      <div style={{ flex: 1, fontSize: 18, color: Dark.text }}> NEWS </div>
    </div>
  );
}
