import React, { useEffect, useState } from "react";
import TopCard from "./TopCard";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import { Dark } from "../../../../Theme/Theme";
import Loading from "react-loading";
import PendingCard from "./PendingCard";
import Search from "./Search";
import { useSelector } from "react-redux";
const backend = new Backend();
export default function Pending() {
  const show = useSelector((state) => state.show);
  const height = window.innerHeight;
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([]);
  const [pendingsearch, setPendingSearch] = useState([]);
  const [limit, setLimit] = useState(0);
  const [total, setTotal] = useState(0);
  const [sl, setSl] = useState(0);

  useEffect(() => {
    loadPending();
  }, []);

  const loadPending = () => {
    setLoading(true);

    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.pending(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setPending(r.pending);
        setPendingSearch(r.pending);
        setLimit(r.limit);
        setTotal(r.pendinglimit);
        setSl(r.sl);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const searchs = (a) => {
    let script = [];
    if (a.length > 1) {
      script = pendingsearch.filter((x) =>
        x.symbol.toLowerCase().includes(a.toLowerCase())
      );
      setPending(script);
    } else {
      setPending(pendingsearch);
    }
  };

  return (
    <div
      style={{
        height: show == 0 ? window.innerHeight - 145 : window.innerHeight - 295,
        backgroundColor: Dark.background,
      }}
    >
      <TopCard limit={limit} total={total} sl={sl} />
      <Search load={(a, b) => loadPending(a, b)} searchs={(a) => searchs(a)} />

      <div
        style={{
          height: show == 0 ? height - 255 : height - 505,
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : pending.length > 0 ? (
          pending.map((i, t) => {
            return <PendingCard key={i.id} item={i} load={loadPending} />;
          })
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
              {" "}
              Nothing here
            </div>
            <div style={{ color: Dark.text }}>No Active Pending Found.</div>
          </div>
        )}
      </div>
    </div>
  );
}
