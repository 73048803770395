const Dark = {
  primary: "#2139b7",
  background: "#000000",
  text: "#ffffff",
  secondary: "#14161b",
  buy: "#1da750",
  sell: "#ff3d32",
  deactive : "rgba(255,255,255,0.6)"
};
const Light = {
  background: "#FFFFFF",
  primary: "#2139b7",
  text: "#000000",
  secoundry: "#f2f2f2",
  buy: "#1da750",
  sell: "#ff3d32",
  deactive : "rgba(0,0,0,0.6)"
};

export { Dark, Light };
