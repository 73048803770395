import React, { useEffect, useState } from "react";
import { Dark } from "../../../../../Theme/Theme";
import { toast } from "react-toast";
import Backend from "../../../../../Backend/Backend";
const backend = new Backend();
export default function Rules(props) {
  const [rules, setRules] = useState("");

  useEffect(() => {
    LoadRules();
  }, []);

  const LoadRules = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };
    backend.rules(data).then((r) => {
      if (r.error == "False") {
        setRules(r.rules);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const agree = () => {
    localStorage.setItem("rules", "1");
    props.close();
  };

  return (
    <div style={{ marginRight: 20, marginLeft: 20 }}>
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
          fontSize:22
        }}
      >
        SERVER RULES & TURMS
      </div>

      <div
        className="scrolldivu"
        style={{ height: "63vh", overflowY: "scroll" }}
      >
        {rules.length > 0
          ? rules.map((i, t) => {
              return (
                <div
                  style={{
                    backgroundColor: Dark.secondary,
                    margin: 10,
                    borderRadius: 10,
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{ padding: 10, color: Dark.text, paddingBottom: 5 }}
                  >
                    {t + 1}.
                  </div>
                  <div style={{ padding: 10, color: Dark.text, paddingTop: 5 }}>
                    {i.msg}.
                  </div>
                </div>
              );
            })
          : null}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 10,
        }}
      >
        <div
          onClick={agree}
          style={{
            height: 40,
            width: 200,
            backgroundColor: Dark.primary,
            borderRadius: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.text,
          }}
        >
          I AGREE
        </div>
      </div>
    </div>
  );
}
