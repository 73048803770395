import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { AiOutlineUser } from "react-icons/ai";
import { LuClipboardList, LuClock } from "react-icons/lu";
import { BiBookmark } from "react-icons/bi";
import { FiBriefcase } from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import Backend from "../../../../Backend/Backend";
import LivePl from "../../../../Healer/LivePl";
import { useDispatch, useSelector } from "react-redux";
import "./Bottom.css";

const backend = new Backend();
export default function BottomBar() {
  const dispatch = useDispatch();

  const show = useSelector((state) => state.show);
  const total = parseFloat(useSelector((state) => state.total)).toFixed(0);

  const path = window.location.pathname;
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [select, setSelect] = useState(false);

  const [loading, setLoading] = useState(0);

  const [modal, setModal] = useState(false);
  const [types, setTypes] = useState(0);
  const [text, setText] = useState("");

  const [intraday, setIntraday] = useState(0);

  const [profitloss, setProfitLoss] = useState(0);

  const [standing, setStanding] = useState([]);
  const [limit, setLimit] = useState(0);
  const [limitfuture, setLimitFuture] = useState(0);
  const [limitmcx, setLimitMcx] = useState(0);
  const [limitoptions, setLimitOptions] = useState(0);
  const [deposit_current, setDepositCurrent] = useState(0);
  const [deposit_total, setDepositTotal] = useState(0);
  const [multiplier_future, setMultiplierFuture] = useState(0);
  const [multiplier_mcx, setMultiplierMcx] = useState(0);
  const [multiplier_options, setMultiplierOpions] = useState(0);

  const [future_hold, setFutureHold] = useState(0);
  const [mcx_hold, setMcxHold] = useState(0);
  const [options_hold, setOptions_hold] = useState(0);

  const showOpen = () => {
    dispatch({ type: "SHOW", payload: 1 });
  };
  const showClose = () => {
    dispatch({ type: "SHOW", payload: 0 });
  };

  useEffect(() => {
    loadHidden();
  }, []);

  const loadHidden = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };
    backend.hidden(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });

        setStanding(r.standing);
        setIntraday(r.intraday == undefined ? 0 : r.intraday);
        setProfitLoss(r.profit_loss);
        setLimit(r.limit_blocked);

        setLimitFuture(r.limit_block_future);
        setLimitMcx(r.limit_block_mcx);
        setLimitOptions(r.limit_block_options);

        setDepositCurrent(r.deposit_current);
        setDepositTotal(r.deposit_total);
        setMultiplierFuture(r.multiplier_future);
        setMultiplierMcx(r.multiplier_mcx);
        setMultiplierOpions(r.multiplier_options);

        setFutureHold(r.future_hold);
        setMcxHold(r.mcx_hold);
        setOptions_hold(r.options_hold);
      } else {
        setModal(true);
        setTypes(3);
        setText(r.message);
      }
    });
  };

  return (
    <div
      key={select}
      style={{
        height: show == 0 ? 90 : 250,
        backgroundColor: Dark.background,
      }}
    >
      {standing.length > 0
        ? standing.map((i) => {
            return <LivePl item={i} key={i.id} total={standing.length} />;
          })
        : null}
      {isKeyboardOpen ? null : (
        <>
          <div
            style={{
              height: "100%",
              backgroundColor: Dark.background,
              display: "flex",
              alignSelf: "center",
              width: "100%",
              justifyContent: "space-around",
              alignItems: "center",
              height: 60,
            }}
          >
            <Link
              onClick={() => setSelect(!select)}
              className="linkdarkmobile"
              to={"Watchlist"}
            >
              <div
                style={{
                  height: 50,
                  width: 50,
                  backgroundColor: Dark.background,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div>
                  <BiBookmark
                    style={{
                      fontSize: 25,
                      color:
                        path == "/" || path == "/Watchlist"
                          ? Dark.primary
                          : Dark.text,
                    }}
                  />
                </div>

                <div
                  style={{
                    fontSize: 15,
                    color:
                      path == "/" || path == "/Watchlist"
                        ? Dark.primary
                        : Dark.text,
                  }}
                >
                  Watchlist
                </div>
              </div>
            </Link>

            <Link
              onClick={() => setSelect(!select)}
              className="linkdarkmobile"
              to={"Standing"}
            >
              <div
                style={{
                  height: 50,
                  width: 50,
                  backgroundColor: Dark.background,
                  borderRadius: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 50,
                    width: 50,
                    backgroundColor: Dark.background,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <FiBriefcase
                      style={{
                        fontSize: 25,
                        color: path == "/Standing" ? Dark.primary : Dark.text,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      fontSize: 15,
                      color: path == "/Standing" ? Dark.primary : Dark.text,
                    }}
                  >
                    Standing
                  </div>
                </div>
              </div>
            </Link>

            <Link
              onClick={() => setSelect(!select)}
              className="linkdarkmobile"
              to={"Pending"}
            >
              <div
                style={{
                  height: 50,
                  width: 50,
                  backgroundColor: Dark.background,
                  borderRadius: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 50,
                    width: 50,
                    backgroundColor: Dark.background,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <LuClock
                      style={{
                        fontSize: 25,
                        color: path == "/Pending" ? Dark.primary : Dark.text,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      fontSize: 15,
                      color: path == "/Pending" ? Dark.primary : Dark.text,
                    }}
                  >
                    Pending
                  </div>
                </div>
              </div>
            </Link>

            <Link
              onClick={() => setSelect(!select)}
              className="linkdarkmobile"
              to={"Trade"}
            >
              <div
                style={{
                  height: 50,
                  width: 50,
                  backgroundColor: Dark.background,
                  borderRadius: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 50,
                    width: 50,
                    backgroundColor: Dark.background,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <LuClipboardList
                      style={{
                        fontSize: 25,
                        color: path == "/Trade" ? Dark.primary : Dark.text,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      fontSize: 15,
                      color: path == "/Trade" ? Dark.primary : Dark.text,
                    }}
                  >
                    Trade
                  </div>
                </div>
              </div>
            </Link>

            <Link
              onClick={() => setSelect(!select)}
              className="linkdarkmobile"
              to={"Profile"}
            >
              <div
                style={{
                  height: 50,
                  width: 50,
                  backgroundColor: Dark.background,
                  borderRadius: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 50,
                    width: 50,
                    backgroundColor: Dark.background,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <AiOutlineUser
                      style={{
                        fontSize: 25,
                        color: path == "/Profile" ? Dark.primary : Dark.text,
                      }}
                    />
                  </div>

                  <div
                    style={{
                      fontSize: 15,
                      color: path == "/Profile" ? Dark.primary : Dark.text,
                    }}
                  >
                    Profile
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div
            onClick={() => (show == 0 ? showOpen() : showClose())}
            style={{
              backgroundColor: Dark.background,
              display: "flex",
              alignSelf: "center",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: 20,
            }}
          >
            {show ? (
              <IoMdArrowDropdown
                style={{
                  fontSize: 40,
                  color: Dark.primary,
                }}
              />
            ) : (
              <IoMdArrowDropup
                style={{
                  fontSize: 40,
                  color: Dark.primary,
                }}
              />
            )}
          </div>

          {show ? (
            <div
              style={{
                height: "100%",
                backgroundColor: Dark.background,
                display: "flex",
                alignSelf: "center",
                flexDirection: "column",
                width: "100%",
                height: 165,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  height: intraday == 0 ? 42 : 29,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: Dark.secondary,
                }}
              >
                <div
                  style={{
                    flex: 0.6,
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 12 }}>LIVE P&L </div>
                  <div
                    style={{
                      paddingRight: 5,
                      color: total > 0 ? Dark.buy : Dark.sell,
                      fontSize: 12,
                    }}
                  >
                    {total}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 12 }}>M</div>
                  <div
                    style={{
                      paddingRight: 5,
                      color: Dark.text,
                      fontSize: 12,
                    }}
                  >
                    F : {multiplier_future} M : {multiplier_mcx} O :{" "}
                    {multiplier_options}
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: intraday == 0 ? 42 : 29,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: Dark.background,
                }}
              >
                <div
                  style={{
                    flex: 0.6,
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 12 }}>BOOKED </div>
                  <div
                    style={{
                      paddingRight: 5,
                      color: total > 0 ? Dark.buy : Dark.sell,
                      fontSize: 12,
                    }}
                  >
                    {parseFloat(profitloss).toFixed(0)}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 10 }}>M-TOTAL</div>
                  <div
                    style={{
                      paddingRight: 5,
                      color: Dark.text,
                      fontSize: 10,
                    }}
                  >
                    F :{" "}
                    {parseFloat(
                      (multiplier_future * deposit_current) / 100
                    ).toFixed(0)}{" "}
                    M :{" "}
                    {parseFloat(
                      (multiplier_mcx * deposit_current) / 100
                    ).toFixed(0)}{" "}
                    O :{" "}
                    {parseFloat(
                      (multiplier_options * deposit_current) / 100
                    ).toFixed(0)}
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: intraday == 0 ? 41 : 29,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: Dark.secondary,
                }}
              >
                <div
                  style={{
                    flex: 0.6,
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 12 }}>NET P&L </div>
                  <div
                    style={{
                      paddingRight: 5,
                      color:
                        parseFloat(total) + parseFloat(profitloss) > 0
                          ? Dark.buy
                          : Dark.sell,
                      fontSize: 12,
                    }}
                  >
                    {parseFloat(
                      parseFloat(total) + parseFloat(profitloss)
                    ).toFixed(0)}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 10 }}>M-USED</div>
                  <div
                    style={{
                      paddingRight: 5,
                      color: Dark.text,
                      fontSize: 10,
                    }}
                  >
                    F : {parseFloat(limitfuture).toFixed(0)} M :{" "}
                    {parseFloat(limitmcx).toFixed(0)} O :{" "}
                    {parseFloat(limitoptions).toFixed(0)}
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: intraday == 0 ? 41 : 29,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: Dark.background,
                }}
              >
                <div
                  style={{
                    flex: 0.6,
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 12 }}>CREDIT </div>
                  <div
                    style={{
                      paddingRight: 5,
                      color: Dark.text,
                      fontSize: 12,
                    }}
                  >
                    {parseFloat(deposit_current).toFixed(0)}
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    color: Dark.text,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1, fontSize: 10 }}>M-FREE</div>
                  <div
                    style={{
                      paddingRight: 5,
                      color: Dark.text,
                      fontSize: 10,
                    }}
                  >
                    F :{" "}
                    {parseFloat(
                      multiplier_future * deposit_current -
                        parseFloat(limitfuture) / 100
                    ).toFixed(0)}{" "}
                    M :{" "}
                    {parseFloat(
                      multiplier_mcx * deposit_current -
                        parseFloat(limitmcx) / 100
                    ).toFixed(0)}{" "}
                    O :{" "}
                    {parseFloat(
                      multiplier_options * deposit_current -
                        parseFloat(limitoptions) / 100
                    ).toFixed(0)}
                  </div>
                </div>
              </div>
              {intraday == 1 ? (
                <div
                  style={{
                    height: 29,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: Dark.secondary,
                  }}
                >
                  <div
                    style={{
                      flex: 0.6,
                      color: Dark.text,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1, fontSize: 10 }}>CARRY </div>
                    <div
                      style={{
                        paddingRight: 5,
                        color: Dark.text,
                        fontSize: 10,
                      }}
                    >
                      F : {future_hold} M : {mcx_hold} O : {options_hold}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      color: Dark.text,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ flex: 1, fontSize: 10 }}>M-CARRY</div>
                    <div
                      style={{
                        paddingRight: 5,
                        color: Dark.text,
                        fontSize: 10,
                      }}
                    >
                      F : {parseFloat(limitfuture / future_hold).toFixed(0)} M :{" "}
                      {parseFloat(limitmcx / mcx_hold).toFixed(0)} O :{" "}
                      {parseFloat(limitoptions / options_hold).toFixed(0)}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </>
      )}
    </div>
  );
}
